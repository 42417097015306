<template>
  <ion-header>
    <ion-toolbar class="toolbar-modal-modalBase">
      <ion-buttons slot="start">
        <ion-button class="button-header-modalBase" @click="cancel"
          >Cancel</ion-button
        ></ion-buttons
      ><slot name="deleteButton"></slot>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <!-- <ion-item>
      <ion-label position="stacked">Your name</ion-label>
      name is the "data"
      <ion-input v-model="name" placeholder="Your name"></ion-input>
    </ion-item> -->
    <slot name="modalContent"></slot>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <slot name="contractorFooter"></slot>
      <slot name="reviewFooter"></slot>
      <slot name="submissionFooter"></slot>
      <slot name="modalFooter"></slot>
    </ion-toolbar>
  </ion-footer>
  <!-- <ion-button>Button</ion-button> -->
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonFooter,

  // IonItem,
  // IonLabel,
  // IonInput,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonButtons,
    IonButton,

    // IonItem,
    // IonLabel,
    // IonInput,
  },
  setup() {
    function cancel() {
      return modalController.dismiss(null, "cancel");
      // return modalController.dismiss("cancel");
    }
    function confirm() {
      return modalController.dismiss(null, "confirm");
    }

    return { cancel, confirm };
  },
});
</script>

<style>
.toolbar-modal-modalBase {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.button-header-modalBase {
  --box-shadow: transparent;
  --color: var(--ion-color-primary-contrast);
}
</style>
