<template>
  <modal-base>
    <template v-slot:modalContent v-if="contractorValues">
      <!-- display from Knock Stoppers -->
      <contractor-display
        :contractorData="contractorValues"
        :logo="imageUrl"
      ></contractor-display>

      <ion-list>
        <!-- edit display info -->
        <ion-item color="primary">
          <ion-label>Edit Display Info</ion-label>
          <info-popover trig="edit-display-info">
            <h3>Name:</h3>
            <p>This is how your company name will be displayed</p>
            <h3>Address:</h3>
            <p>
              This is the address that will be displayed and used for
              determining the distance a user is from your company.
            </p>
            <h3>Logo:</h3>
            <p>This is the logo that will be displayed for your company.</p>
          </info-popover>
        </ion-item>
        <ion-item>
          <ion-label>Name:</ion-label>
          <ion-input
            placeholder="enter name"
            v-model="contractorValues.name"
            @ionInput="validateName($event.target.value)"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="nameValidation"
          ></ion-checkbox>
        </ion-item>

        <ion-item>
          <ion-label> Address: </ion-label>
          <autocomplete-search
            @search-location="updateAddress"
            :displayAddressButton="true"
            :displayCheckBox="false"
            :startingAddress="contractorValues.formattedAddress"
          >
          </autocomplete-search>
        </ion-item>

        <ion-item>
          <ion-label>Logo:</ion-label>
          <ion-button color="light" size="small">
            <input type="file" accept="image/*" @change="selectFile" />
          </ion-button>
        </ion-item>

        <!-- edit general info -->
        <ion-item color="primary">
          <ion-label>Add Additional Information</ion-label>
          <info-popover trig="additional-info">
            <h3>Short Description:</h3>
            <p>
              Add a description of your company and services you provide. This
              will be displayed to users when they click into your company
              details.
            </p>
            <h3>Reviews:</h3>
            <p>
              To help you get started, we allow you to upload up to 5 reviews.
              Please read the terms and conditions on the next page for more
              information.
            </p>
          </info-popover>
        </ion-item>
        <ion-item>
          <ion-label>Short Description:</ion-label>
          <ion-textarea
            placeholder="enter a short description describing your company"
            v-model="contractorValues.description"
          ></ion-textarea>
        </ion-item>
        <ion-item>
          <ion-label>Upload up to 5 Reviews:</ion-label>
          <ion-button color="light" @click="openModal(UploadReviewsModal)"
            >Upload Reviews</ion-button
          >
        </ion-item>

        <ion-item color="primary">
          <ion-label>Edit Contact Information</ion-label>
          <info-popover trig="edit-display-info">
            <h3>Website URL:</h3>
            <p>
              Please enter in your website as "website name" + ".com/.org/ect."
              Example: websiteName.com
            </p>
            <h3>Phone Number:</h3>
            <p>
              This is the phone number that will be shown to users. Enter a 10
              digit number with no special characters. The input box will
              automatically format your phone number once 10 numbers have been
              entered.
            </p>
            <h3>Email Address:</h3>
            <p>
              This is the email address that will be shown to users. Example:
              name@provider.com
            </p>
          </info-popover>
        </ion-item>

        <ion-item>
          <ion-label>Website URL:</ion-label>
          <ion-input
            v-model="contractorValues.website"
            @ionInput="validateWebsite($event.target.value)"
            placeholder="website.com"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="websiteValidation"
          ></ion-checkbox>
        </ion-item>
        <!-- phone number -->
        <ion-item>
          <ion-label>Phone Number:</ion-label>
          <ion-input
            placeholder="888-888-8888"
            v-model="contractorValues.phoneNumber"
            @ionInput="validatePhone($event.target.value)"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="phoneValidation"
          ></ion-checkbox>
        </ion-item>
        <!-- email -->
        <ion-item>
          <ion-label>Email Address:</ion-label>
          <ion-input
            @ionInput="validateEmail($event.target.value)"
            placeholder="email@provider.com"
            v-model="contractorValues.email"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="emailValidation"
          ></ion-checkbox>
        </ion-item>
      </ion-list>
    </template>
    <template v-slot:reviewFooter>
      <ion-button
        expand="block"
        @click="updateContractor()"
        v-if="
          nameValidation &&
          websiteValidation &&
          phoneValidation &&
          emailValidation
        "
        >Update</ion-button
      ><ion-button v-else :disabled="true" expand="block">Update</ion-button>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import ContractorDisplay from "../components/ContractorDisplay.vue";

import AutocompleteSearch from "../components/AutocompleteSearch.vue";
import UploadReviewsModal from "../components/UploadReviewsModal.vue";
import InfoPopover from "../components/InfoPopover.vue";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, app } from "../firebase/config";

import {
  IonItem,
  // IonIcon,
  // IonChip,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonTextarea,
  IonCheckbox,
  // IonAccordion,
  // IonAccordionGroup,
  // IonSelect,
  // IonSelectOption,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { getUserData } from "../composables/getUserData.js";
import { getGeoNeighbors } from "../composables/getGeoNeighbors.js";
import { typAsync } from "../composables/typAsync.js";
export default defineComponent({
  components: {
    IonButton,
    // SliderComponent,
    IonItem,
    IonLabel,
    // IonChip,
    // IonIcon,
    // InfoPopover,
    IonList,
    IonInput,
    // IonAccordion,
    // IonAccordionGroup,
    // IonSelect,
    // IonSelectOption,
    IonCheckbox,
    ModalBase,
    ContractorDisplay,
    AutocompleteSearch,
    IonTextarea,
    InfoPopover,
  },
  setup() {
    let contractorRef;
    const contractorValues = ref("");
    const selectedFile = ref(null);
    const imageUrl = ref(null);
    const storageBucketName = "contractor-logos/";
    const storage = getStorage(app);
    const contractorId = ref("");
    // validations
    const nameValidation = ref(false);
    const websiteValidation = ref(false);
    const phoneValidation = ref(false);
    const emailValidation = ref(false);
    // const phoneValidation = ref(false)

    onMounted(() => {
      return typAsync(async () => {
        // get contractorId
        let tempUserData = await getUserData();
        let contractorId = tempUserData.contractorId;

        // get contractor values
        contractorRef = doc(db, "contractors", contractorId);
        contractorValues.value = await getContractor(contractorId);
        contractorValues.value["distance"] = 0;
        contractorValues.value.multiplier = 1;

        // console.log("contractor values: ", contractorValues.value);
        await getContractorLogo();

        validateName(contractorValues.value.name);
        validateWebsite(contractorValues.value.website);
        validatePhone(contractorValues.value.phoneNumber);
        validateEmail(contractorValues.value.email);
      });
    });

    async function getContractor(id) {
      contractorId.value = id;

      const docSnap = await getDoc(contractorRef);

      return new Promise((resolve) => {
        if (docSnap.exists()) {
          if (docSnap.data()) {
            resolve(docSnap.data());
          }
        }
      });
    }

    function selectFile(event) {
      selectedFile.value = event.target.files[0];
      imageUrl.value = URL.createObjectURL(selectedFile.value);
      contractorValues.value.logo = `${storageBucketName}${selectedFile.value.name}`;
    }

    async function getContractorLogo() {
      let currentUrl = contractorValues.value.logo;

      if (currentUrl) {
        let tempUrl = currentUrl.split(":");
        if (tempUrl[0] !== "https") {
          let url = await getDownloadURL(
            storageRef(storage, `${storageBucketName}${currentUrl}`)
          );

          if (url) {
            contractorValues.value.logo = url;
            contractorValues.value.logo = url;
          } else {
            contractorValues.value.logo = "";
          }
        }
      }
    }

    function updateAddress(addressValues) {
      return typAsync(async () => {
        let geohashes = await getGeoNeighbors(
          null,
          addressValues.geodata.lat,
          addressValues.geodata.lng,
          8
        );

        geohashes = geohashes[0];

        geohashes = {
          geohash8: geohashes,
          geohash7: geohashes.slice(0, 7),
          geohash6: geohashes.slice(0, 6),
          geohash5: geohashes.slice(0, 5),
          geohash4: geohashes.slice(0, 4),
          geohash3: geohashes.slice(0, 3),
          geohash2: geohashes.slice(0, 2),
          geohash1: geohashes.slice(0, 1),
        };

        contractorValues.value = {
          ...contractorValues.value,
          addressComponents: addressValues.addressComponents,
          formattedAddress: addressValues.formattedAddress,
          geodata: addressValues.geodata,
          geohashes: geohashes,
        };
        // console.log("updateAddress: ", contractorValues.value);
      });
    }

    // validate name
    function validateName(nameValue) {
      if (nameValue.length) {
        nameValidation.value = true;
      } else {
        nameValidation.value = false;
      }
    }

    // validate url
    function validateWebsite(url) {
      const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
      // If website is valid set websiteIsValid to true
      if (regex.test(url)) {
        websiteValidation.value = true;
      } else {
        websiteValidation.value = false;
      }
    }

    // validate phone number
    function validatePhone(phone) {
      const phoneNumber = phone.replace(/\D/g, ""); // Remove all non-numeric characters
      const formattedPhoneNumber = phoneNumber
        .slice(0, 10)
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // Format the phone number
      phone = formattedPhoneNumber; // Update the input value

      if (formattedPhoneNumber.split("-").length === 3) {
        phoneValidation.value = true;
      } else {
        phoneValidation.value = false;
      }
    }

    // validate email
    function validateEmail(tempEmail) {
      var emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRegex.test(tempEmail)) {
        emailValidation.value = true;
      } else {
        emailValidation.value = false;
      }
    }

    function updateContractor() {
      return typAsync(async () => {
        // console.log("update contractor values: ", contractorValues.value);

        // upload photo
        if (selectedFile.value) {
          const fileRef = storageRef(
            storage,
            storageBucketName + contractorId.value
          );
          await uploadBytes(fileRef, selectedFile.value);
        }

        // console.log("File uploaded successfully");
        let temp = contractorValues.value;

        await updateDoc(contractorRef, {
          addressComponents: temp.addressComponents,
          description: temp.description,
          email: temp.email,
          formattedAddress: temp.formattedAddress,
          geodata: temp.geodata,
          geohash1: temp.geohash1,
          geohash2: temp.geohash2,
          geohash3: temp.geohash3,
          geohash4: temp.geohash4,
          geohash5: temp.geohash5,
          geohash6: temp.geohash6,
          geohash7: temp.geohash7,
          geohash8: temp.geohash8,
          logo: contractorId.value,
          name: temp.name,
          phoneNumber: temp.phoneNumber,
          website: temp.website,
        });
      });
    }

    async function openModal(modalType) {
      const modal = await modalController.create({
        component: modalType,

        componentProps: {
          contractorId: contractorId.value,
        },
      });
      modal.present();
      await modal.onWillDismiss();
    }

    return {
      contractorValues,
      updateContractor,
      updateAddress,
      selectedFile,
      selectFile,
      validateName,
      validateWebsite,
      validateEmail,
      validatePhone,
      phoneValidation,
      nameValidation,
      websiteValidation,
      emailValidation,
      imageUrl,
      openModal,
      UploadReviewsModal,
    };
  },
});
</script>

<style scoped></style>
