// https://www.npmjs.com/package/ngeohash

//Geohash values per value i.e. #4 = jcb6, #7 = jcb6dgj
// #   km       miles
// 1   ±2500    1553.5
// 2   ±630     391.5
// 3   ±78      48.5
// 4   ±20      12.5
// 5   ±2.4     1.5
// 6   ±0.61    0.5
// 7   ±0.076   0.05
// 8   ±0.019   0.01

//if pass in geohash, must provide geohash 1 longer than precision
//precision: 6 = 50 miles, 5 = 10 miles
//pass in either geohash or lat/lng. Insert the rest as 0
export async function getGeoNeighbors(geohashOne, lat, lng, precision) {
  var geohash = require("ngeohash");

  let hashOne;
  let hashTwo;
  let geoNeighbors = [];

  let neighborsOne;
  let neighborsTwo;
  let test;

  if (lat !== false && lng !== false) {
    hashOne = geohash.encode(lat, lng, precision + 1);
  } else if (geohash !== false) {
    hashOne = geohashOne;
  } else {
    console.error("Error: getGeoNeighbors called with invalid data");
  }

  hashTwo = hashOne.slice(0, -1);

  neighborsOne = [...geohash.neighbors(hashOne), hashOne];
  neighborsTwo = [...geohash.neighbors(hashTwo), hashTwo];

  for (let i = 0; i < 9; i++) {
    test = neighborsOne[i].slice(0, -1);
    for (let j = 0; j < 9; j++) {
      if (test === neighborsTwo[j] && !geoNeighbors.includes(neighborsTwo[j])) {
        geoNeighbors.push(neighborsTwo[j]);
        break;
      }
    }
  }

  return geoNeighbors;
}
