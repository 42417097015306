<!-- https://vue3datepicker.com/ -->
<!-- https://antoniandre.github.io/vue-cal/#api -->
<template>
  <modal-base>
    <template v-slot:modalContent v-if="valuesMounted">
      <ion-list>
        <ion-item color="light">
          <ion-label>Appointment Limits: </ion-label>
          <info-popover trig="appointment-limits">
            <h3>Hours:</h3>
            <p>
              This will set the time within which appointments can be scheduled.
            </p>
            <h3>Appointments Per Hour</h3>
            <p>
              This is the number of appointments that can be overlapped per
              hour. May want to set this to the number of employees that can run
              leads.
            </p>
            <h3>Days in Advance</h3>
            <p>
              This is the number of days in advance of warning before an
              appointment.
            </p>
          </info-popover>
        </ion-item>
        <ion-list>
          <ion-item>
            <ion-label>Hours: </ion-label>
            <slider-component
              :options="startTime"
              @slider-value="updateStartHours"
            ></slider-component>
            -
            <slider-component
              :options="endTime"
              @slider-value="updateEndHours"
            ></slider-component>
          </ion-item>

          <ion-item>
            <ion-label>Appointments Per Hour</ion-label>
            <slider-component
              :options="perHourOptions"
              @slider-value="updatePerHour"
            ></slider-component>
          </ion-item>
          <ion-item>
            <ion-label>Days in Advance</ion-label>
            <ion-select placeholder="next day" v-model="daysInAdvance">
              <ion-select-option value="next">next day</ion-select-option>
              <ion-select-option value="two">2 days</ion-select-option
              ><ion-select-option value="three">3 days</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>

        <ion-item color="light">
          <ion-label>Available Days: </ion-label>
          <info-popover trig="available-days">
            <p>
              Dark icons are selected and light icons are deselected.This will
              set the days with which appointments can be scheduled.
            </p>
          </info-popover>
        </ion-item>

        <!-- <div v-for="day in days" :key="day">
            <p v-if="day.data === true">{{ day.icon }},</p>
          </div> -->
        <ion-item>
          <div
            v-for="day in days"
            :key="day"
            style="display: flex"
            @click="updateDaysInAdvance(day.name, day.data)"
          >
            <ion-chip v-if="day.data === true">
              {{ day.icon }}
            </ion-chip>
            <ion-chip v-else :outline="true">
              {{ day.icon }}
            </ion-chip>
          </div>
        </ion-item>

        <ion-item color="light">
          <ion-label>Offseason </ion-label>
          <info-popover trig="offseason">
            <p>
              Offseason will set the timeframe of the year with which
              appointments will not be scheduled. For example set this for the
              winter in northern climates. Click "Reset Offseason" to reset
              offseason or to turn it off.
            </p>
          </info-popover>
          <ion-button color="clear" @click="clearOffseasonValues()"
            >Reset Offseason</ion-button
          >
        </ion-item>
        <ion-list>
          <calendar-popover
            :options="offseasonStartOptions"
            @date-selected="updateStartOffseason"
            ref="clearStartDate"
          ></calendar-popover>
          <calendar-popover
            :options="offseasonEndOptions"
            @date-selected="updateEndOffseason"
            ref="clearEndDate"
          ></calendar-popover>
        </ion-list>
        <ion-item color="light">
          <ion-label>Appointment Radius </ion-label>
          <info-popover trig="appointment-radius">
            <p>
              This sets the geological radius from the main buisness office with
              which appointments can be scheduled within.
            </p>
          </info-popover>
        </ion-item>
        <ion-item>
          <ion-label>Radius From Office</ion-label>
          <slider-component
            :options="radiusOptions"
            @slider-value="updateRadius"
          ></slider-component>
        </ion-item>

        <ion-item color="light">
          <ion-label>Block Off Days </ion-label>
          <info-popover trig="block-off-days">
            <p>
              Select the days that appointments should not be scheduled, like
              company holidays. Click "Blocked Off Dates" to view the current
              blocked off dates. To delete a date, click the trash icon next to
              the blocked off date.
            </p>
          </info-popover>
        </ion-item>
        <ion-list>
          <calendar-popover
            :options="blockOptions"
            @date-selected="updateBlockValues"
            ref="clearblock"
          ></calendar-popover>
          <ion-accordion-group>
            <ion-accordion>
              <ion-item slot="header">
                <ion-label>Blocked Off Dates</ion-label>
              </ion-item>
              <ion-list v-for="day in blockDays" :key="day" slot="content">
                <ion-item>
                  <ion-label>{{ day.dateFormatted }}</ion-label
                  ><ion-icon
                    :src="trash"
                    @click="deleteBlockDay(day.disableDate)"
                  ></ion-icon
                ></ion-item>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>
        </ion-list>
      </ion-list>
    </template>
    <template v-slot:reviewFooter>
      <ion-button expand="block" @click="updateAppointmentPreferences()"
        >Update</ion-button
      >
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import SliderComponent from "../components/SliderComponent.vue";
import CalendarPopover from "../components/CalendarPopover.vue";
import InfoPopover from "../components/InfoPopover.vue";
import { trash } from "ionicons/icons";
import { typAsync } from "../composables/typAsync.js";

import {
  IonItem,
  IonIcon,
  IonChip,
  IonLabel,
  IonList,
  IonButton,
  IonAccordion,
  IonAccordionGroup,
  IonSelect,
  IonSelectOption,
  // loadingController,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { getUserData } from "../composables/getUserData.js";
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

export default defineComponent({
  components: {
    CalendarPopover,
    IonButton,
    SliderComponent,
    IonItem,
    IonLabel,
    IonChip,
    IonIcon,
    InfoPopover,
    IonList,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    ModalBase,
  },
  setup() {
    const days = ref({
      monday: { data: true, icon: "Mo", name: "monday" },
      tuesday: { data: true, icon: "Tu", name: "tuesday" },
      wednesday: { data: true, icon: "We", name: "wednesday" },
      thursday: { data: true, icon: "Th", name: "thursday" },
      friday: { data: true, icon: "Fr", name: "friday" },
      saturday: { data: true, icon: "Sa", name: "saturday" },
      sunday: { data: true, icon: "Su", name: "sunday" },
    });

    const defaultValues = {
      disableDays: true,
      selectDay: false,
      hideWeekdays: [], //1-7
      time: false,
      transitions: false,
      activeView: "month",
      disableViews: ["week", "day", "year", "years"],
      style: "width: 180px; height: auto",
    };
    const valuesMounted = ref(false);

    let tempPref = null;
    // offseason values
    const clearStartDate = ref(null);
    const clearEndDate = ref(null);
    const offseasonStart = ref({});
    const offseasonEnd = ref({});
    const offseasonStartOptions = ref({
      ...defaultValues,
      title: "select start date:",
      trig: "offseason-start-trigger",
      date: offseasonStart.value,
      minDate: "currentDate",
      maxDate: offseasonEnd.value.disableDate,
    });
    const offseasonEndOptions = ref({
      ...defaultValues,
      title: "Select End Date:",
      trig: "offseason-end-trigger",
      date: offseasonEnd.value,
      minDate: offseasonStart.value.disableDate,
      maxDate: "",
    });
    function updateStartOffseason(emitValues) {
      offseasonStart.value = emitValues;
      offseasonStartOptions.value.date = emitValues;
      offseasonEndOptions.value.minDate = emitValues.disableDate;
    }
    function updateEndOffseason(emitValues) {
      offseasonEnd.value = emitValues;
      offseasonEndOptions.value.date = emitValues;
      offseasonStartOptions.value.maxDate = emitValues.disableDate;
    }
    function clearOffseasonValues() {
      offseasonStart.value = {};
      offseasonEnd.value = {};
      offseasonStartOptions.value = {
        ...offseasonStartOptions.value,
        date: "",
        maxDate: "",
      };
      offseasonEndOptions.value = {
        ...offseasonEndOptions.value,
        date: "",
        minDate: "",
      };
      clearStartDate.value.clearDates();
      clearEndDate.value.clearDates();
    }

    //update hours
    const hoursStart = ref(8);
    const hoursEnd = ref(17);
    const startTime = ref({
      type: "time",
      start: 7,
      step: 1,
      end: hoursEnd.value,
      time: hoursStart.value,
      trig: "start-time",
    });
    const endTime = ref({
      type: "time",
      start: hoursStart.value,
      end: 21,
      step: 1,
      time: hoursEnd.value,
      trig: "end-time",
    });

    function updateStartHours(emitValue) {
      hoursStart.value = emitValue;
      startTime.value.time = emitValue;
      endTime.value.start = emitValue;
    }

    function updateEndHours(emitValue) {
      hoursEnd.value = emitValue;
      endTime.value.time = emitValue;
      startTime.value.end = emitValue;
    }

    // update appointments per hour
    const perHour = ref(1);
    const perHourOptions = ref({
      type: "number",
      start: 1,
      end: 10,
      step: 1,
      number: perHour.value,
      trig: "per-hour",
    });
    function updatePerHour(emitValue) {
      perHour.value = emitValue;
      perHourOptions.value.number = emitValue;
    }

    // days in advance
    const daysInAdvance = ref("next");
    function updateDaysInAdvance(dayId, truth) {
      if (truth === true) {
        days.value[dayId].data = false;
      } else {
        days.value[dayId].data = true;
      }
    }

    // block options
    const blockDays = ref({});
    const blockOptions = ref({
      ...defaultValues,
      title: "Select Date to Block Off:",
      trig: "block-trigger",
      minDate: "currentDate",
      maxDate: "",
      disabledDays: blockDays.value,
      disableDisplay: true,
    });
    function updateBlockValues(emitValues) {
      if (blockDays.value !== {}) {
        let tempDate = emitValues.disableDate;
        if (blockDays.value[tempDate]) {
          deleteBlockDay(tempDate);
        } else {
          blockDays.value[tempDate] = emitValues;
          // blockOptions.value.disableDays = blockDays.value;
        }
      }
    }

    function deleteBlockDay(day) {
      delete blockDays.value[day];
      // blockOptions.value.disableDays = blockDays.value;
    }

    // radius
    const radius = ref(5);
    const radiusOptions = ref({
      type: "miles",
      start: 5,
      end: 25,
      step: 5,
      number: radius.value,
      trig: "radius",
    });

    function updateRadius(value) {
      radius.value = value;
      radiusOptions.value.number = radius.value;
    }

    onMounted(() => {
      return typAsync(async () => {
        let tempUserData = await getUserData();
        await getAppointmentPreferences(tempUserData.contractorId);
        valuesMounted.value = true;
      });
    });

    const contractorId = ref("");

    async function getAppointmentPreferences(id) {
      //awaited in onMounted
      contractorId.value = id;

      const docRef = doc(db, "preferences", id);
      const docSnap = await getDoc(docRef);

      return new Promise((resolve) => {
        if (docSnap.exists()) {
          if (docSnap.data().appointmentPreferences) {
            tempPref = docSnap.data().appointmentPreferences;

            // hours
            startTime.value = {
              ...startTime.value,
              end: tempPref.hours.end,
              time: tempPref.hours.start,
            };

            endTime.value = {
              ...endTime.value,
              start: tempPref.hours.start,

              time: tempPref.hours.end,
            };
            // per hour
            perHourOptions.value.number = tempPref.perHour;
            // days in advance
            daysInAdvance.value = tempPref.daysInAdvance;

            // update days
            days.value.monday.data = tempPref.availableDays.monday;
            days.value.tuesday.data = tempPref.availableDays.tuesday;
            days.value.wednesday.data = tempPref.availableDays.wednesday;
            days.value.thursday.data = tempPref.availableDays.thursday;
            days.value.friday.data = tempPref.availableDays.friday;
            days.value.saturday.data = tempPref.availableDays.saturday;
            days.value.sunday.data = tempPref.availableDays.sunday;

            // offseason
            offseasonStart.value = tempPref.offseason.start;
            offseasonEnd.value = tempPref.offseason.end;

            offseasonStartOptions.value = {
              ...offseasonStartOptions.value,
              date: tempPref.offseason.start,
              maxDate: tempPref.offseason.end.disableDate,
            };

            offseasonEndOptions.value = {
              ...offseasonEndOptions.value,
              date: tempPref.offseason.end,
              minDate: tempPref.offseason.start.disableDate,
            };

            // radius
            radiusOptions.value.number = tempPref.radius;
            //  blocks
            blockDays.value = tempPref.blockedDays;
            blockOptions.value.disabledDays = tempPref.blockedDays;
          }
        }
        resolve();
      });
    }

    async function updateFirebase(document) {
      // awaited in updateAppointmentPreferences
      let docRef = doc(db, "preferences", contractorId.value);
      // console.log("tempPref: ", tempPref);

      if (tempPref) {
        // console.log("tried to update pref");
        await updateDoc(docRef, {
          appointmentPreferences: document,
        });
      } else {
        // console.log("tried to set pref");
        await setDoc(docRef, {
          appointmentPreferences: document,
          appointments: {},
        });
      }
    }

    function updateAppointmentPreferences() {
      return typAsync(async () => {
        let tempOffseasonStart = "";
        let tempOffseasonEnd = "";
        if (offseasonStart.value.date && offseasonEnd.value.date) {
          tempOffseasonStart = offseasonStart.value;
          tempOffseasonEnd = offseasonEnd.value;
        }
        let appointmentPreferences = {
          hours: {
            start: hoursStart.value,
            end: hoursEnd.value,
          },
          perHour: perHour.value,
          daysInAdvance: daysInAdvance.value,
          availableDays: {
            monday: days.value.monday.data,
            tuesday: days.value.tuesday.data,
            wednesday: days.value.wednesday.data,
            thursday: days.value.thursday.data,
            friday: days.value.friday.data,
            saturday: days.value.saturday.data,
            sunday: days.value.sunday.data,
          },
          offseason: {
            start: tempOffseasonStart,
            end: tempOffseasonEnd,
          },
          radius: radius.value,
          blockedDays: blockDays.value,
        };

        await updateFirebase(appointmentPreferences);

        // console.log("tried to add permission");
        let contractorRef = doc(db, "contractors", contractorId.value);
        await updateDoc(contractorRef, {
          radius: radius.value,
        });

        modalController.dismiss("confirm");
      });
    }

    return {
      days,
      updateDaysInAdvance,
      daysInAdvance,
      trash,
      blockOptions,
      blockDays,
      deleteBlockDay,
      updateBlockValues,
      offseasonStartOptions,
      offseasonEndOptions,
      updateStartOffseason,
      updateEndOffseason,
      clearOffseasonValues,
      clearStartDate,
      clearEndDate,
      startTime,
      endTime,
      hoursStart,
      hoursEnd,
      updateStartHours,
      updateEndHours,
      updatePerHour,
      perHourOptions,
      updateAppointmentPreferences,
      radius,
      radiusOptions,
      updateRadius,
      valuesMounted,
    };
  },
});
</script>

<style scoped></style>
