import axios from "axios";

export async function getPermanantGeocode(address) {
  let permanantGeocode = {
    addressComponents: {},
  };

  const response = await axios.get(
    `https://api.geocod.io/v1.7/geocode?street=${address.street}&city=${address.city}&state=${address.state}&postal_code${address.zip}&api_key=${process.env.VUE_APP_GEOCODEIO_API_KEY}`
  );

  const data = response.data;

  if (data.results && data.results.length > 0) {
    // console.log("place: ", data.results[0]);
    let addressData = data.results[0].address_components;
    if (addressData.number) {
      permanantGeocode.addressComponents.streetNumber = addressData.number;
    }
    if (addressData.formatted_street) {
      permanantGeocode.addressComponents.street = addressData.formatted_street;
    }
    if (addressData.city) {
      permanantGeocode.addressComponents.city = addressData.city;
    }
    if (addressData.state) {
      permanantGeocode.addressComponents.state = addressData.state;
    }
    if (addressData.zip) {
      permanantGeocode.addressComponents.zip = addressData.zip;
    }
    if (addressData.county) {
      let county = addressData.county.split(" County");
      permanantGeocode.addressComponents.county = county[0];
    }

    let lat = Number(data.results[0].location.lat);
    let lng = Number(data.results[0].location.lng);

    permanantGeocode.formattedAddress = data.results[0].formatted_address;
    permanantGeocode.geodata = {
      lat: lat,
      lng: lng,
      coordinates: [lng, lat],
    };
  } else {
    console.error("No results found.");
  }

  return {
    permanantGeocode,
  };
}
