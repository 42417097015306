<template>
  <modal-base>
    <template v-slot:modalContent>
      <!-- display reviews -->
      <div v-if="keys > 0">
        <div v-for="review in reviews" :key="review">
          <div class="review-parent-review-buttons">
            <div class="review-container-review-buttons">
              <div class="item-box-review-button">
                <star-rating
                  class="star-rating-review-buttons"
                  :startingStars="review.rating"
                  :editStars="false"
                  :showRating="false"
                ></star-rating>

                <ion-icon
                  form="HTMLFormElement"
                  @click="deleteReview(review)"
                  :src="trash"
                ></ion-icon>
              </div>
              <div class="item-box-review-button">
                <p>{{ review.name }}</p>
                <p>
                  {{ review.timeAgo }}
                </p>
              </div>
              <!-- more text option -->
              <div v-if="review.lessText">
                <div>
                  {{ review.lessText }}
                  <button
                    class="showMore-review-buttons"
                    v-if="review.lessText !== review.text"
                    @click="showMore(review)"
                  >
                    Show More
                  </button>
                </div>
              </div>
              <div v-else>
                <div>{{ review.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <ion-item>
          <ion-label>no reviews uploaded</ion-label>
        </ion-item>
      </div>
      <!-- Enter reviews -->

      <ion-item color="primary">
        <ion-label>Add up to 5 reviews</ion-label>
        <info-popover trig="review-info">
          <h3>Date:</h3>
          <p>This is the date the review was origionally created.</p>
          <h3>Rating:</h3>
          <p>
            This is on a 1-5 scale. Round down to the nearest star value. If the
            origional review was out of 10, then divide the value by 2 and round
            down to the nearest star value. Example: (8.5/10 stars) -> (4/5
            stars). Uploaded review ratings will not effect your overall rating.
          </p>
          <h3>Name:</h3>
          <p>
            This is the name of the individual that origionally created the
            review.
          </p>
          <h3>Review:</h3>
          <p>
            This is the text of the origional review. If there was no text leave
            this blank.
          </p>
        </info-popover>
      </ion-item>
      <div v-if="keys < 5">
        <ion-list>
          <calendar-popover
            :options="calendarOptions"
            @date-selected="updateDate"
            ref="resetDate"
          ></calendar-popover>

          <ion-item>
            <ion-label>Select Rating:</ion-label>
            <p style="padding-right: 10px">{{ starValue }}</p>
            <star-rating
              :startingStars="0"
              :editStars="true"
              :showRating="false"
              ref="resetStarValue"
              @rating-updated="updateStarValue"
            ></star-rating>
          </ion-item>
          <ion-item>
            <ion-label>Reviewer Name: </ion-label>
            <ion-input
              type="text"
              pattern="[a-zA-Z]+"
              v-model="name"
              placeholder="First Last"
              maxlength="50"
            ></ion-input>
          </ion-item>
          <ion-item counter="true">
            <ion-label>Enter Review:</ion-label>
            <ion-textarea
              v-model="reviewText"
              placeholder="optional"
              :autoGrow="true"
              maxlength="1000"
            ></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-button id="terms-trigger" color="light"
                >Terms and Conditions</ion-button
              ></ion-label
            >
            <ion-checkbox v-model="terms" :disabled="true"></ion-checkbox>
          </ion-item>
          <ion-modal trigger="terms-trigger" id="ion-modal-review-terms">
            <ion-content class="ion-padding">
              <ion-item color="light">
                <ion-label> Terms and Conditions: </ion-label>
              </ion-item>
              <ion-item>
                I hereby confirm that I have obtained written consent from the
                original creator of this review to display it on Knock Stoppers.
                I understand and acknowledge that it is illegal to upload fake
                or misleading reviews, and I certify that this review is
                authentic and has not been altered in any way except for
                reformatting for display on Knock Stoppers, which I have
                received permission from the original creator of the review to
                do. Furthermore, I acknowledge that failure to comply with the
                rules outlined above may result in permanent suspension from
                using both this website and Knock Stoppers, as well as potential
                legal repercussions.
              </ion-item>
              <ion-item>
                I've read and agree to these terms and conditions
                <ion-checkbox v-model="terms"></ion-checkbox>
              </ion-item>
            </ion-content>
          </ion-modal>
        </ion-list>
      </div>
      <div v-else>
        <ion-item>
          <ion-label>Remove a review to add another</ion-label>
        </ion-item>
      </div>
    </template>

    <template v-slot:reviewFooter>
      <ion-button
        expand="block"
        @click="updateReviews()"
        v-if="dateObject && starValue && name && terms"
        >Add Review</ion-button
      ><ion-button v-else :disabled="true" expand="block"
        >Add Review</ion-button
      >
    </template>
  </modal-base>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted, defineComponent, ref } from "vue";
import StarRating from "../components/StarRating.vue";
import CalendarPopover from "../components/CalendarPopover.vue";
import ModalBase from "../components/ModalBase.vue";
import InfoPopover from "../components/InfoPopover.vue";
import { trash } from "ionicons/icons";
import { updateDoc, doc, getDoc, deleteField } from "firebase/firestore";
import { db } from "../firebase/config";
import { typAsync } from "../composables/typAsync.js";
import {
  // IonInput,
  IonLabel,
  IonItem,
  IonButton,
  // IonImg,
  IonIcon,
  IonInput,
  IonTextarea,
  IonList,
  IonModal,
  IonContent,
  IonCheckbox,
  // IonContent,
  // IonModal,
  // IonList,
  // IonButton,
  // IonSelect,
  // IonSelectOption,
  // IonButtons,
  // IonContent,
  // IonToolbar,
  // IonItem,
  // IonSearchbar,
  // IonDatetime,
  // IonSegment,
  // IonSegmentButton,
  // IonFooter,
  // IonHeader,
  // IonImg,
  // loadingController,
} from "@ionic/vue";

export default defineComponent({
  props: {
    contractorId: {
      type: String,
      required: true,
    },
  },
  components: {
    IonItem,
    IonList,
    IonButton,
    StarRating,
    // IonImg,
    IonIcon,
    ModalBase,
    IonLabel,
    IonInput,
    IonTextarea,
    IonModal,
    IonContent,
    IonCheckbox,
    CalendarPopover,
    InfoPopover,
    // IonContent,
  },
  setup(props) {
    const uid = ref(props.contractorId);
    const contractorRef = doc(db, "contractors", uid.value);
    const keys = ref(5);
    const reviews = ref("");
    const name = ref("");
    const starValue = ref("");
    const reviewText = ref("");
    const terms = ref(null);
    const dateObject = ref("");
    const resetStarValue = ref(null);
    const resetDate = ref(null);

    const calendarOptions = {
      disableDays: false,
      selectDay: false,
      hideWeekdays: [], //1-7
      time: false,
      transitions: false,
      activeView: "month",
      disableViews: ["week", "day", "year", "years"],
      style: "width: 180px; height: auto",
      title: "Review Date:",
      trig: "review-date-trigger",
      // date: offseasonStart.value,
      minDate: "",
      maxDate: "currentDate",
    };

    onMounted(() => {
      return typAsync(async () => {
        // console.log("tried to get reviews");
        reviews.value = await getReviews();
        Object.keys(reviews.value).forEach((key) => {
          getLessText(reviews.value[key]);
        });
        updateKeys();
      });
    });

    // watch(reviews, (newValues) => {
    //   keys.value = Object.keys(newValues).length;
    // });

    function updateKeys() {
      keys.value = Object.keys(reviews.value).length;
    }

    function showMore(reviewValue) {
      reviewValue.lessText = "";
    }

    function updateStarValue(stars) {
      starValue.value = stars;
    }

    function updateDate(dateValue) {
      dateObject.value = dateValue;
    }

    async function deleteReview(reviewValue) {
      return typAsync(async () => {
        let reviewToDelete = reviewValue.reviewName;
        let deletion = `uploadedReviews.${reviewToDelete}`;

        await updateDoc(contractorRef, {
          [deletion]: deleteField(),
        });

        delete reviews.value[reviewToDelete];
        updateKeys();
      });
    }

    async function getReviews() {
      const docSnap = await getDoc(contractorRef);
      return new Promise((resolve) => {
        if (docSnap.exists() && docSnap.data()) {
          if (docSnap.data().uploadedReviews) {
            resolve(docSnap.data().uploadedReviews);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
    }

    function getLessText(reviewValue) {
      let lessTextToAdd = "";
      let origionalText = reviewValue.text;

      if (origionalText.length > 90) {
        lessTextToAdd = `${origionalText.substring(0, 90)}...`;
        reviewValue.lessText = lessTextToAdd;
      }
    }

    function updateReviews() {
      return typAsync(async () => {
        // set contractors doc

        let reviewName = name.value.toLowerCase().replace(/\s/g, "");

        reviews.value = {
          ...reviews.value,
          [reviewName]: {
            reviewName: reviewName,
            name: name.value,
            date: dateObject.value,
            publishedDate: dateObject.value.date,
            rating: starValue.value,
            text: reviewText.value,
            terms: terms.value,
          },
        };

        await updateDoc(contractorRef, {
          uploadedReviews: reviews.value,
        });

        getLessText(reviews.value[reviewName]);

        // console.log("review upload: ", reviews.value);

        name.value = "";
        dateObject.value = "";
        starValue.value = "";
        reviewText.value = "";
        terms.value = false;
        resetStarValue.value.resetValue();
        resetDate.value.clearDates();
        updateKeys();
      });
    }

    return {
      reviews,
      showMore,
      updateStarValue,
      updateDate,
      deleteReview,
      updateReviews,
      resetDate,
      name,
      starValue,
      reviewText,
      terms,
      calendarOptions,
      dateObject,
      keys,
      trash,
      resetStarValue,
    };
  },
});
</script>

<style scoped>
.review-parent-review-buttons {
  background-color: gainsboro;
  display: flex;

  flex-direction: column;
  height: auto;
  border-radius: 10px 10px 10px 10px;
  /* margin: 0px 5px; */
  /* gap: 10px; */
  /* padding: 5px 10px; */
  overflow: hidden;

  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  /* align-content: center; */

  justify-content: center;
}

.star-rating-review-buttons {
  height: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  padding-right: 10px;
}

.showMore-review-buttons {
  color: blue;
  background-color: transparent;
}

.review-container-review-buttons {
  padding-left: 15px;
  padding-right: 15px;
}
.item-box-review-button {
  height: 40px;
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  /* padding-left: 15px;
  padding-right: 15px; */
  border-bottom: 1px solid gainsboro;
}

#ion-modal-review-terms {
  --height: 50%;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}
</style>
