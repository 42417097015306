<template>
  <div
    v-if="contractorData.multiplier > 1"
    class="content-true-parent-portal-page"
  >
    <ion-item class="name-contractorDisplay" lines="none">
      <ion-label
        ><h1>{{ contractorData.name }}</h1>
        <p class="address-contractorDisplay">
          {{ contractorData.formattedAddress }}
        </p></ion-label
      >
    </ion-item>
    <div class="div-childs-portal-page">
      <div class="div-childOne-portal-page">
        <!-- <p>Rating: {{ contractor.rating }}/5</p> -->
        <p>Reported Knocks: {{ contractorData.knocks.counter }}</p>
        <p>Distance: {{ contractorData.distance }} miles</p>
      </div>

      <div class="img-childTwo-portal-page">
        <ion-img
          v-if="image"
          style="height: 100px"
          :src="contractorLogo"
          @ionError="handleError()"
        />
      </div>
    </div>
  </div>
  <div v-else class="content-false-parent-portal-page">
    <ion-item class="name-contractorDisplay" lines="none">
      <ion-label
        ><h1>{{ contractorData.name }}</h1>
        <p class="address-contractorDisplay">
          {{ contractorData.formattedAddress }}
        </p></ion-label
      >
    </ion-item>

    <div class="div-childs-portal-page">
      <div class="div-childOne-portal-page">
        <!-- <p>Rating: {{ contractor.rating }}/5</p> -->

        <p>Reported Knocks: {{ contractorData.knocks.counter }}</p>
        <p>Distance: {{ contractorData.distance }} miles</p>
      </div>

      <div class="img-childTwo-portal-page">
        <ion-img
          v-if="image"
          style="height: 100px"
          :src="contractorLogo"
          @ionError="handleError()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import { IonImg, IonItem, IonLabel } from "@ionic/vue";
export default defineComponent({
  components: {
    IonImg,
    IonItem,
    IonLabel,
  },
  props: {
    contractorData: {
      type: Object,
    },
    logo: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    // const propValues = ref(props);
    const image = ref(true);
    function handleError() {
      image.value = false;
    }

    const contractorLogo = ref(null);

    watch(props, (newValue) => {
      getLogo(newValue);
    });

    function getLogo(propValues) {
      if (propValues.logo) {
        contractorLogo.value = propValues.logo;
        image.value = true;
        // console.log("found logo: ", contractorLogo.value);
      } else {
        contractorLogo.value = propValues.contractorData.logo;
        image.value = true;
        // console.log("did not find logo: ", contractorLogo.value);
      }
    }

    getLogo(props);

    return { image, handleError, contractorLogo };
  },

  // setup(props){

  // }
});
</script>
<style scoped>
.content-true-parent-portal-page {
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.4);
  background-color: #ffd16d;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  height: 180px;
  border-radius: 20px 20px 20px 20px;
  margin: 5px 5px;

  padding: 0px 10px;
  overflow: hidden;
  cursor: pointer;
  /* align-content: center; */
}

.content-false-parent-portal-page {
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.4);
  background-color: gainsboro;
  display: flex;
  flex-direction: column;

  /* justify-content: flex-start; */
  height: 180px;
  border-radius: 20px 20px 20px 20px;
  margin: 5px 5px;

  padding-left: 10px;
  overflow: hidden;
  cursor: pointer;
  /* align-content: center; */
}

.div-childs-portal-page {
  display: flex;
  flex-direction: row;
  max-height: 110px;
  width: 100%;
  /* align-items: center;
  justify-content: center; */
  /* max-height: 100px; */
  /* margin-top: 5px; */
}

.div-childOne-portal-page {
  max-height: 90px;
  width: 50%;
  background-color: transparent;
  flex-direction: column;
  padding-right: 10px;
}

.img-childTwo-portal-page {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 50%;
}

.name-portal-page {
  /* height: 55px; */
  height: 30px;
  width: 100%;
}

.address-contractorDisplay {
  font-size: 10px;
}

.name-contractorDisplay {
  --background: transparent;

  --min-height: 50px;
  --padding-start: 0px;
  --overflow: hidden;
  --text-overflow: ellipsis;
  --margin: 0px;
}
</style>
