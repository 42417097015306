<template>
  <ion-item
    ><ion-label>{{ options.title }}</ion-label>

    <ion-button color="light" :id="options.trig">{{
      dateFormatted
    }}</ion-button>
    <ion-checkbox
      v-if="checkbox"
      :disabled="true"
      v-model="checkboxValue"
      style="margin-left: 10px"
    >
    </ion-checkbox>
  </ion-item>

  <ion-modal :trigger="options.trig" id="ion-modal-calendar-popover">
    <ion-content :scroll-y="false" :scroll-x="false">
      <div
        style="height: auto; width: auto; padding: 10px"
        v-if="loadCalendar === true"
      >
        <vue-cal
          class="vuecal--date-picker"
          hide-view-selector
          :hide-weekdays="options.hideWeekdays"
          :time="options.time"
          :transitions="options.transitions"
          :active-view="options.activeView"
          :disable-views="options.disableViews"
          :style="options.style"
          :selected-date="dateSelected"
          :disable-days="disabledDays"
          @cell-click="dateSelected = $event"
          :min-date="minimumDate"
          :max-date="maximumDate"
        >
        </vue-cal>
      </div>
    </ion-content>
  </ion-modal>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from "vue";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import {
  // IonPopover,
  IonModal,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
  IonCheckbox,
  // IonList,
  // IonIcon,
  // IonAccordion,
  // IonAccordionGroup,
} from "@ionic/vue";

export default defineComponent({
  components: {
    VueCal,
    // IonPopover,
    IonModal,
    IonContent,
    IonItem,
    IonLabel,
    IonButton,
    IonCheckbox,
    // IonList,
    // IonIcon,
    // IonAccordion,
    // IonAccordionGroup,
  },

  emits: ["date-selected"],
  props: {
    options: { type: Object, required: true },
    checkbox: { type: Boolean, required: false, default: false },
  },
  setup(props, context) {
    const optionValues = ref(props.options);
    const dateSelected = ref("");
    const minimumDate = ref("");
    const maximumDate = ref("");
    // const disableDays = ref([])
    const dateFormatted = ref("select date");
    let currentDate = new Date();
    // let lastWeek = new Date();
    // lastWeek.setDate(currentDate.getDate() - 7);
    const disabledDays = ref([]);
    const checkboxValue = ref(false);
    const loadCalendar = ref(false);

    onMounted(async () => {
      await updateDisabledDays();
      await findDates();
      loadCalendar.value = true;
    });

    watch(props, async (newValue) => {
      optionValues.value = newValue.options;
      if (newValue.date) {
        dateSelected.value = newValue.disableDate;
        dateFormatted.value = newValue.formattedDate;
      }
      await updateDisabledDays();
      await findDates();
    });

    function findDates() {
      return new Promise((resolve) => {
        let minDate = optionValues.value.minDate;
        let maxDate = optionValues.value.maxDate;

        if (minDate) {
          if (minDate === "currentDate") {
            minimumDate.value = `${currentDate}`;
          } else {
            minimumDate.value = minDate;
          }
        } else {
          minimumDate.value = "";
        }

        if (maxDate) {
          // console.log("max date: ", maxDate);
          if (maxDate === "currentDate") {
            maximumDate.value = `${currentDate}`;
            // console.log("maximum date: ", maximumDate.value);
          } else {
            maximumDate.value = maxDate;
          }
        } else {
          maximumDate.value = "";
        }

        let date = optionValues.value.date;
        if (date) {
          if (date.dateFormatted) {
            dateFormatted.value = date.dateFormatted;
          }
        }

        resolve();
      });
    }

    function clearDates() {
      dateSelected.value = "";
      dateFormatted.value = "select date";
    }

    function updateDisabledDays() {
      return new Promise((resolve) => {
        disabledDays.value = [];
        if (optionValues.value.disabledDays) {
          Object.keys(optionValues.value.disabledDays).forEach((key) => {
            disabledDays.value.push(key);
          });
        }
        resolve();
      });
    }

    watch(dateSelected, (newValue) => {
      if (newValue instanceof Date) {
        let dateFormat = formatDate(newValue);
        if (!optionValues.value.disableDisplay) {
          dateFormatted.value = dateFormat;
        }

        checkboxValue.value = true;

        let emitValue = {
          disableDate: newValue.format(),
          dateFormatted: dateFormat,
          date: `${newValue}`,
        };
        context.emit("date-selected", emitValue);
      }
    });

    function formatDate(date) {
      let tempArr = `${date}`;
      tempArr = tempArr.split(" ");
      tempArr = `${tempArr[0]}, ${tempArr[1]} ${tempArr[2]} ${tempArr[3]}`;
      return tempArr;
    }

    return {
      dateSelected,
      dateFormatted,
      checkboxValue,
      disabledDays,
      minimumDate,
      maximumDate,
      clearDates,
      optionValues,
      loadCalendar,
    };
  },
});
</script>
<style scoped>
#ion-modal-calendar-popover {
  --height: 255px;
  --width: 200px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}
</style>
