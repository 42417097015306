const blogIds = [
  "EJ2X5kNMeY01H9Zr30i6",
  "ICajYD2ksbVbRGuBuCyv",
  "KM8s9LJT6ZvP2lOuWcrB",
  "lsOFTXcRvYe1YTsCKdRf",
  "p3WrTCmBYIwkzwKJIJp2",
  "v4KZf4Pidr48HxC2Pdh2",
  "vWK0NbfNgZm4Y0NlarQ4",
  "xg1bl6n12mMRFXq7QaPu",
];

const mnCities = [
  "minneapolis-mn",
  "saint-paul-mn",
  "rochester-mn",
  "bloomington-mn",
  "duluth-mn",
  "brooklyn-park-mn",
  "plymouth-mn",
  "woodbury-mn",
  "lakeville-mn",
  "blaine-mn",
  "maple-grove-mn",
  "st-cloud-mn",
  "eagan-mn",
  "burnsville-mn",
  "coon-rapids-mn",
  "eden-prairie-mn",
  "apple-valley-mn",
  "edina-mn",
  "minnetonka-mn",
  "st-louis-park-mn",
  "mankato-mn",
  "moorhead-mn",
  "shakopee-mn",
  "maplewood-mn",
  "cottage-grove-mn",
  "richfield-mn",
  "roseville-mn",
  "inver-grove-heights-mn",
  "savage-mn",
  "andover-mn",
  "brooklyn-center-mn",
  "fridley-mn",
  "ramsey-mn",
  "prior-lake-mn",
  "chaska-mn",
  "oakdale-mn",
  "shoreview-mn",
  "rosemount-mn",
  "owatonna-mn",
  "austin-mn",
  "elk-river-mn",
  "chanhassen-mn",
  "winona-mn",
  "faribault-mn",
  "white-bear-lake-mn",
  "farmington-mn",
  "champlin-mn",
  "new-brighton-mn",
  "crystal-mn",
  "golden-valley-mn",
  "hastings-mn",
  "columbia-heights-mn",
  "lino-lakes-mn",
  "otsego-mn",
  "new-hope-mn",
  "willmar-mn",
  "west-st-paul-mn",
  "northfield-mn",
  "forest-lake-mn",
  "south-st-paul-mn",
  "sartell-mn",
  "stillwater-mn",
  "st-michael-mn",
  "hopkins-mn",
  "albert-lea-mn",
  "anoka-mn",
  "red-wing-mn",
  "ham-lake-mn",
  "buffalo-mn",
  "hugo-mn",
  "hibbing-mn",
  "bemidji-mn",
  "hutchinson-mn",
  "monticello-mn",
  "north-mankato-mn",
  "alexandria-mn",
  "brainerd-mn",
  "robbinsdale-mn",
  "new-ulm-mn",
  "fergus-falls-mn",
  "sauk-rapids-mn",
  "worthington-mn",
  "marshall-mn",
  "rogers-mn",
  "waconia-mn",
  "mounds-view-mn",
  "lake-elmo-mn",
  "cloquet-mn",
  "vadnais-heights-mn",
  "north-st-paul-mn",
  "big-lake-mn",
  "east-bethel-mn",
  "st-peter-mn",
  "mendota-heights-mn",
  "grand-rapids-mn",
  "north-branch-mn",
  "victoria-mn",
  "little-canada-mn",
  "fairmont-mn",
  "hermantown-mn",
  "cambridge-mn",
  "detroit-lakes-mn",
  "arden-hills-mn",
  "waseca-mn",
  "mound-mn",
  "east-grand-forks-mn",
  "little-falls-mn",
  "oak-grove-mn",
  "st-anthony-village-mn",
  "baxter-mn",
  "thief-river-falls-mn",
  "minnetrista-mn",
  "waite-park-mn",
  "st-francis-mn",
  "virginia-mn",
  "new-prague-mn",
  "dayton-mn",
  "orono-mn",
  "mahtomedi-mn",
  "wyoming-mn",
  "albertville-mn",
  "shorewood-mn",
  "belle-plaine-mn",
  "crookston-mn",
  "spring-lake-park-mn",
  "isanti-mn",
  "st-joseph-mn",
  "kasson-mn",
  "medina-mn",
  "jordan-mn",
  "corcoran-mn",
  "stewartville-mn",
  "delano-mn",
  "byron-mn",
  "litchfield-mn",
  "carver-mn",
  "zimmerman-mn",
  "international-falls-mn",
  "glencoe-mn",
  "chisago-city-mn",
  "st-paul-park-mn",
  "lake-city-mn",
  "montevideo-mn",
  "la-crescent-mn",
  "falcon-heights-mn",
  "morris-mn",
  "north-oaks-mn",
  "redwood-falls-mn",
  "circle-pines-mn",
  "becker-mn",
  "lindstrom-mn",
  "luverne-mn",
  "elko-new-market-mn",
  "princeton-mn",
  "windom-mn",
  "lonsdale-mn",
  "watertown-mn",
  "oak-park-heights-mn",
  "st-james-mn",
  "chisholm-mn",
  "dilworth-mn",
  "sauk-centre-mn",
  "rockford-mn",
  "nowthen-mn",
  "wayzata-mn",
  "wadena-mn",
  "newport-mn",
  "cannon-falls-mn",
  "columbus-mn",
  "cold-spring-mn",
  "le-sueur-mn",
  "pipestone-mn",
  "goodview-mn",
  "park-rapids-mn",
  "rice-lake-mn",
  "st-charles-mn",
  "grant-mn",
  "scandia-mn",
  "centerville-mn",
  "norwood-young-america-mn",
  "bayport-mn",
  "montrose-mn",
  "pine-island-mn",
  "deephaven-mn",
  "zumbrota-mn",
  "independence-mn",
  "long-prairie-mn",
  "two-harbors-mn",
  "mora-mn",
  "melrose-mn",
  "hanover-mn",
  "st-augusta-mn",
  "perham-mn",
  "plainview-mn",
  "eveleth-mn",
  "sleepy-eye-mn",
  "breckenridge-mn",
  "benson-mn",
  "montgomery-mn",
  "annandale-mn",
  "jackson-mn",
  "eagle-lake-mn",
  "rush-city-mn",
  "blue-earth-mn",
  "ely-mn",
  "pine-city-mn",
  "proctor-mn",
  "milaca-mn",
  "staples-mn",
  "chatfield-mn",
  "afton-mn",
  "greenfield-mn",
  "mountain-iron-mn",
  "dodge-center-mn",
  "caledonia-mn",
  "albany-mn",
  "barnesville-mn",
  "cokato-mn",
  "roseau-mn",
  "foley-mn",
  "granite-falls-mn",
  "cohasset-mn",
  "breezy-point-mn",
  "glenwood-mn",
  "osseo-mn",
  "pelican-rapids-mn",
  "moose-lake-mn",
  "mayer-mn",
  "lake-crystal-mn",
  "le-center-mn",
  "wabasha-mn",
  "lexington-mn",
  "paynesville-mn",
  "crosslake-mn",
  "spring-valley-mn",
  "janesville-mn",
  "pequot-lakes-mn",
  "wells-mn",
  "rockville-mn",
  "sandstone-mn",
  "madelia-mn",
  "olivia-mn",
  "crosby-mn",
  "excelsior-mn",
  "gaylord-mn",
  "arlington-mn",
  "st-bonifacius-mn",
  "hawley-mn",
  "lauderdale-mn",
  "winsted-mn",
  "maple-lake-mn",
  "howard-lake-mn",
  "aitkin-mn",
  "clearwater-mn",
  "cologne-mn",
  "tracy-mn",
  "rice-mn",
  "nisswa-mn",
  "eyota-mn",
  "springfield-mn",
  "ortonville-mn",
  "waverly-mn",
  "hoyt-lakes-mn",
  "mountain-lake-mn",
  "slayton-mn",
  "coleraine-mn",
  "blooming-prairie-mn",
  "hinckley-mn",
  "lester-prairie-mn",
  "kenyon-mn",
  "silver-bay-mn",
  "rushford-mn",
  "warroad-mn",
  "oronoco-mn",
  "braham-mn",
  "ada-mn",
  "waterville-mn",
  "osakis-mn",
  "rock-creek-mn",
  "dundas-mn",
  "lakefield-mn",
  "stacy-mn",
  "long-lake-mn",
  "maple-plain-mn",
  "lakeland-mn",
  "canby-mn",
  "mapleton-mn",
  "spring-park-mn",
  "gilbert-mn",
  "aurora-mn",
  "avon-mn",
  "tonka-bay-mn",
  "warren-mn",
  "lewiston-mn",
  "madison-mn",
  "richmond-mn",
  "dassel-mn",
  "dawson-mn",
  "wheaton-mn",
  "pierz-mn",
  "fulda-mn",
  "fosston-mn",
  "clara-city-mn",
  "babbitt-mn",
  "winnebago-mn",
  "starbuck-mn",
  "appleton-mn",
  "hayfield-mn",
  "minneota-mn",
  "menahga-mn",
  "medford-mn",
  "grand-marais-mn",
  "red-lake-falls-mn",
  "winthrop-mn",
  "frazee-mn",
  "preston-mn",
  "glyndon-mn",
  "new-york-mills-mn",
  "renville-mn",
  "elbow-lake-mn",
  "new-london-mn",
  "royalton-mn",
  "bagley-mn",
  "edgerton-mn",
  "madison-lake-mn",
  "goodhue-mn",
  "mahnomen-mn",
  "fairfax-mn",
  "spring-grove-mn",
  "new-richland-mn",
  "adrian-mn",
  "shafer-mn",
  "dellwood-mn",
  "nicollet-mn",
  "elgin-mn",
  "cottonwood-mn",
  "grand-meadow-mn",
  "harris-mn",
  "tyler-mn",
  "wanamingo-mn",
  "atwater-mn",
  "mantorville-mn",
  "spicer-mn",
  "lake-shore-mn",
  "truman-mn",
  "sherburn-mn",
  "taylors-falls-mn",
  "east-gull-lake-mn",
  "lake-st-croix-beach-mn",
  "eden-valley-mn",
  "harmony-mn",
  "watkins-mn",
  "hector-mn",
  "parkers-prairie-mn",
  "bird-island-mn",
  "scanlon-mn",
  "houston-mn",
  "baudette-mn",
  "keewatin-mn",
  "nashwauk-mn",
  "henderson-mn",
  "carlton-mn",
  "le-roy-mn",
  "biwabik-mn",
  "hilltop-mn",
  "morristown-mn",
  "walker-mn",
  "buhl-mn",
  "deer-river-mn",
  "pine-river-mn",
  "mazeppa-mn",
  "morgan-mn",
  "hallock-mn",
  "emily-mn",
  "henning-mn",
  "birchwood-village-mn",
  "silver-lake-mn",
  "battle-lake-mn",
  "west-concord-mn",
  "clarkfield-mn",
  "hancock-mn",
  "landfall-mn",
  "blackduck-mn",
  "browerville-mn",
  "isle-mn",
  "bovey-mn",
  "kerkhoven-mn",
  "stockton-mn",
  "kimball-mn",
  "lilydale-mn",
  "onamia-mn",
  "st-stephen-mn",
  "rushford-village-mn",
  "dover-mn",
  "lamberton-mn",
  "raymond-mn",
  "gibbon-mn",
  "fertile-mn",
  "westbrook-mn",
  "cleveland-mn",
  "st-clair-mn",
  "courtland-mn",
  "holdingford-mn",
  "walnut-grove-mn",
  "sebeka-mn",
  "wabasso-mn",
  "hampton-mn",
  "belgrade-mn",
  "lake-park-mn",
  "elysian-mn",
  "lanesboro-mn",
  "brownton-mn",
  "greenwood-mn",
  "twin-valley-mn",
  "kasota-mn",
  "hoffman-mn",
  "mabel-mn",
  "welcome-mn",
  "trimont-mn",
  "karlstad-mn",
  "clarks-grove-mn",
  "hills-mn",
  "cass-lake-mn",
  "greenbush-mn",
  "freeport-mn",
  "lake-benton-mn",
  "rollingstone-mn",
  "motley-mn",
  "ellendale-mn",
  "adams-mn",
  "la-prairie-mn",
  "ottertail-mn",
  "clear-lake-mn",
  "marine-on-st-croix-mn",
  "minnesota-lake-mn",
  "buffalo-lake-mn",
  "clarissa-mn",
  "taconite-mn",
  "brownsdale-mn",
  "loretto-mn",
  "brooten-mn",
  "center-city-mn",
  "grove-city-mn",
  "barnum-mn",
  "hill-city-mn",
  "sabin-mn",
  "marble-mn",
  "hendricks-mn",
  "randall-mn",
  "green-isle-mn",
  "heron-lake-mn",
  "jasper-mn",
  "butterfield-mn",
  "deerwood-mn",
  "mcintosh-mn",
  "evansville-mn",
  "balaton-mn",
  "stephen-mn",
  "alden-mn",
  "ironton-mn",
  "halstad-mn",
  "kandiyohi-mn",
  "hamburg-mn",
  "glenville-mn",
  "brownsville-mn",
  "audubon-mn",
  "foreston-mn",
  "ranier-mn",
  "good-thunder-mn",
  "hokah-mn",
  "bertha-mn",
  "browns-valley-mn",
  "eagle-bend-mn",
  "ivanhoe-mn",
  "argyle-mn",
  "littlefork-mn",
  "elmore-mn",
  "minnetonka-beach-mn",
  "amboy-mn",
  "gem-lake-mn",
  "graceville-mn",
  "lyle-mn",
  "cook-mn",
  "sunfish-lake-mn",
  "willernie-mn",
  "floodwood-mn",
  "prinsburg-mn",
  "cosmos-mn",
  "claremont-mn",
  "verndale-mn",
  "pillager-mn",
  "brandon-mn",
  "brewster-mn",
  "geneva-mn",
  "sacred-heart-mn",
  "carlos-mn",
  "jenkins-mn",
  "lafayette-mn",
  "franklin-mn",
  "rothsay-mn",
  "ulen-mn",
  "kiester-mn",
  "ellsworth-mn",
  "pennock-mn",
  "racine-mn",
  "stewart-mn",
  "upsala-mn",
  "ashby-mn",
  "bethel-mn",
  "altura-mn",
  "clearbrook-mn",
  "new-germany-mn",
  "randolph-mn",
  "danube-mn",
  "fifty-lakes-mn",
  "sturgeon-lake-mn",
  "wrenshall-mn",
  "vermillion-mn",
  "wykoff-mn",
  "lynd-mn",
  "miltona-mn",
  "milan-mn",
  "badger-mn",
  "kellogg-mn",
  "tower-mn",
  "fisher-mn",
  "akeley-mn",
  "oklee-mn",
  "new-auburn-mn",
  "waubun-mn",
  "fountain-mn",
  "morton-mn",
  "rose-creek-mn",
  "bigfork-mn",
  "chokio-mn",
  "erskine-mn",
  "ogilvie-mn",
  "woodland-mn",
  "herman-mn",
  "remer-mn",
  "willow-river-mn",
  "alvarado-mn",
  "clinton-mn",
  "nevis-mn",
  "comfrey-mn",
  "hanska-mn",
  "pine-springs-mn",
  "mcgregor-mn",
  "barrett-mn",
  "ghent-mn",
  "round-lake-mn",
  "wood-lake-mn",
  "rushmore-mn",
  "emmons-mn",
  "underwood-mn",
  "lancaster-mn",
  "new-munich-mn",
  "jeffers-mn",
  "newfolden-mn",
  "bricelyn-mn",
  "russell-mn",
  "st-marys-point-mn",
  "vergas-mn",
  "garfield-mn",
  "darwin-mn",
  "long-beach-mn",
  "lowry-mn",
  "lakeland-shores-mn",
  "askov-mn",
  "deer-creek-mn",
  "plato-mn",
  "swanville-mn",
  "medicine-lake-mn",
  "grey-eagle-mn",
  "vernon-center-mn",
  "wilmont-mn",
  "calumet-mn",
  "dexter-mn",
  "sanborn-mn",
  "hartland-mn",
  "cuyuna-mn",
  "canton-mn",
  "maynard-mn",
  "buckman-mn",
  "cyrus-mn",
  "st-martin-mn",
  "hollandale-mn",
  "murdock-mn",
  "middle-river-mn",
  "ceylon-mn",
  "finlayson-mn",
  "belview-mn",
  "hackensack-mn",
  "dakota-mn",
  "granada-mn",
  "hendrum-mn",
  "skyline-mn",
  "beaver-creek-mn",
  "nerstrand-mn",
  "plummer-mn",
  "eitzen-mn",
  "chandler-mn",
  "bowlus-mn",
  "vesta-mn",
  "kensington-mn",
  "backus-mn",
  "gonvick-mn",
  "st-hilaire-mn",
  "wilton-mn",
  "utica-mn",
  "freeborn-mn",
  "kelliher-mn",
  "milroy-mn",
  "hayward-mn",
  "lake-wilson-mn",
  "lake-lillian-mn",
  "hewitt-mn",
  "climax-mn",
  "oslo-mn",
  "wahkon-mn",
  "pease-mn",
  "hanley-falls-mn",
  "echo-mn",
  "cromwell-mn",
  "pemberton-mn",
  "peterson-mn",
  "storden-mn",
  "gary-mn",
  "bigelow-mn",
  "ostrander-mn",
  "ruthton-mn",
  "villard-mn",
  "gilman-mn",
  "northrop-mn",
  "currie-mn",
  "dennison-mn",
  "beardsley-mn",
  "elrosa-mn",
  "donnelly-mn",
  "dalton-mn",
  "frost-mn",
  "orr-mn",
  "rutledge-mn",
  "flensburg-mn",
  "bluffton-mn",
  "sobieski-mn",
  "lucan-mn",
  "okabena-mn",
  "lewisville-mn",
  "lismore-mn",
  "ogema-mn",
  "waldorf-mn",
  "hitterdal-mn",
  "greenwald-mn",
  "garrison-mn",
  "minnesota-city-mn",
  "magnolia-mn",
  "hardwick-mn",
  "tenstrike-mn",
  "south-haven-mn",
  "kingston-mn",
  "mendota-mn",
  "nelson-mn",
  "meire-grove-mn",
  "shelly-mn",
  "watson-mn",
  "lake-bronson-mn",
  "easton-mn",
  "holland-mn",
  "grygla-mn",
  "kennedy-mn",
  "big-falls-mn",
  "winger-mn",
  "bellechester-mn",
  "felton-mn",
  "callaway-mn",
  "kettle-river-mn",
  "warba-mn",
  "dent-mn",
  "forada-mn",
  "steen-mn",
  "wright-mn",
  "delavan-mn",
  "wendell-mn",
  "iona-mn",
  "elizabeth-mn",
  "porter-mn",
  "winton-mn",
  "campbell-mn",
  "waltham-mn",
  "longville-mn",
  "zumbro-falls-mn",
  "palisade-mn",
  "williams-mn",
  "conger-mn",
  "roosevelt-mn",
  "clements-mn",
  "grasston-mn",
  "northome-mn",
  "kilkenny-mn",
  "millville-mn",
  "kinney-mn",
  "bena-mn",
  "mapleview-mn",
  "bellingham-mn",
  "blomkest-mn",
  "boyd-mn",
  "coates-mn",
  "bingham-lake-mn",
  "laporte-mn",
  "shevlin-mn",
  "taunton-mn",
  "heidelberg-mn",
  "miesville-mn",
  "elkton-mn",
  "hammond-mn",
  "chickamaw-beach-mn",
  "dunnell-mn",
  "erhard-mn",
  "meadowlands-mn",
  "twin-lakes-mn",
  "brook-park-mn",
  "clontarf-mn",
  "roscoe-mn",
  "elba-mn",
  "burtrum-mn",
  "harding-mn",
  "lastrup-mn",
  "wolverton-mn",
  "beaver-bay-mn",
  "odin-mn",
  "ormsby-mn",
  "federal-dam-mn",
  "foxhome-mn",
  "garvin-mn",
  "brooks-mn",
  "marietta-mn",
  "riverton-mn",
  "de-graff-mn",
  "brookston-mn",
  "biscay-mn",
  "elmdale-mn",
  "avoca-mn",
  "woodstock-mn",
  "perley-mn",
  "goodridge-mn",
  "iron-junction-mn",
  "quamba-mn",
  "effie-mn",
  "mentor-mn",
  "odessa-mn",
  "mckinley-mn",
  "danvers-mn",
  "trommald-mn",
  "trosky-mn",
  "millerville-mn",
  "comstock-mn",
  "alpha-mn",
  "squaw-lake-mn",
  "minneiska-mn",
  "borup-mn",
  "sunburg-mn",
  "alberta-mn",
  "revere-mn",
  "st-anthony-mn",
  "holt-mn",
  "st-leo-mn",
  "lengby-mn",
  "georgetown-mn",
  "west-union-mn",
  "holloway-mn",
  "arco-mn",
  "bejou-mn",
  "beltrami-mn",
  "bruno-mn",
  "turtle-river-mn",
  "fort-ripley-mn",
  "new-trier-mn",
  "darfur-mn",
  "nimrod-mn",
  "seaforth-mn",
  "viking-mn",
  "bock-mn",
  "nielsville-mn",
  "la-salle-mn",
  "richville-mn",
  "zemple-mn",
  "dumont-mn",
  "lake-henry-mn",
  "solway-mn",
  "dundee-mn",
  "genola-mn",
  "kerrick-mn",
  "strandquist-mn",
  "wanda-mn",
  "evan-mn",
  "walters-mn",
  "wolf-lake-mn",
  "spring-hill-mn",
  "whalan-mn",
  "nassau-mn",
  "nashua-mn",
  "sargeant-mn",
  "tintah-mn",
  "clitherall-mn",
  "kent-mn",
  "kenneth-mn",
  "manhattan-beach-mn",
  "cedar-mills-mn",
  "henriette-mn",
  "ihlen-mn",
  "tamarack-mn",
  "vining-mn",
  "halma-mn",
  "taopi-mn",
  "wilder-mn",
  "dovray-mn",
  "farwell-mn",
  "st-rosa-mn",
  "gully-mn",
  "manchester-mn",
  "hadley-mn",
  "mizpah-mn",
  "hazel-run-mn",
  "st-vincent-mn",
  "leonidas-mn",
  "hatfield-mn",
  "norcross-mn",
  "urbank-mn",
  "myrtle-mn",
  "delhi-mn",
  "sedan-mn",
  "westport-mn",
  "leonard-mn",
  "mcgrath-mn",
  "regal-mn",
  "humboldt-mn",
  "trail-mn",
  "denham-mn",
  "aldrich-mn",
  "doran-mn",
  "cobden-mn",
  "louisburg-mn",
  "correll-mn",
  "strathcona-mn",
  "boy-river-mn",
  "florence-mn",
  "johnson-mn",
  "hillman-mn",
  "donaldson-mn",
  "barry-mn",
  "funkley-mn",
  "kinbrae-mn",
];

module.exports = {
  slugs: {
    cities: mnCities,
    blogIds: blogIds,
  },
};
