import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY_KS,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN_KS,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID_KS,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET_KS,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID_KS,
  appId: process.env.VUE_APP_FIREBASE_APP_ID_KS,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID_KS,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

// const app1 = initializeApp({ ...firebaseConfig1, name: "app1" });
// const app2 = initializeApp(firebaseConfig2, "app2");

const db = getFirestore(app);
const auth = getAuth(app);
// const db2 = getFirestore(app2);

export { db, auth, analytics, app, performance, logEvent };
