import { showLoading } from "../composables/showLoading.js";
import { displayError } from "../composables/displayError.js";
import { loadingController, alertController } from "@ionic/vue";

export async function typAsync(asyncFunction) {
  const loadingPromise = showLoading();
  let result;
  let timeoutId = null;

  // Set a timeout to call reloadPage after 10 seconds
  timeoutId = setTimeout(async () => {
    await reloadPage();
  }, 10000);

  try {
    console.log("try");
    await loadingPromise;
    result = await asyncFunction();
  } catch (error) {
    console.log("catch");
    await displayError(error);
  } finally {
    console.log("finally");
    // Clear the timeout

    await dismissLoading();
    clearTimeout(timeoutId);
  }

  return result;
}

async function dismissLoading() {
  await loadingController.getTop().then(async (loadingOverlay) => {
    if (loadingOverlay) {
      await loadingOverlay.dismiss();
    } else {
      console.error("error: typAsync could not find loading controller");
    }
  });
}

async function reloadPage() {
  await dismissLoading();
  const alert = await alertController.create({
    header: "Timeout Occurred",
    // subHeader: "Reload page or wait.",
    message: "Check your internet connection and reload page.",
    buttons: [
      "Cancel",
      {
        text: "Reload Page",
        role: "confirm",
        handler: () => {
          return location.reload();
        },
      },
    ],
  });

  await alert.present();
}
