<template>
  <ion-button form="HTMLFormElement" :id="options.trig" color="light">{{
    display
  }}</ion-button>

  <ion-popover :trigger="options.trig" side="bottom" style="--width: 100%">
    <ion-content :scroll-y="false" :scroll-x="false">
      <ion-item>{{ display }}</ion-item>
      <ion-range
        :min="options.start"
        :max="options.end"
        :ticks="true"
        :snaps="true"
        :step="options.step"
        v-model="selection"
      ></ion-range> </ion-content
  ></ion-popover>
</template>
<script>
import { defineComponent, watch, ref, onMounted } from "vue";
import {
  IonItem,
  IonRange,
  IonButton,
  IonContent,
  IonPopover,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonItem,

    IonRange,
    IonButton,
    IonContent,
    IonPopover,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
  },
  emits: ["slider-value"],
  setup(props, context) {
    const label = ref("");
    const slider = ref(false);
    const selection = ref(0);
    const display = ref("");
    const optionValues = ref(props.options);

    onMounted(() => {
      setSliderValues();
    });

    watch(props, (newValue) => {
      optionValues.value = newValue.options;
      setSliderValues();
    });

    watch(selection, (newValue) => {
      if (newValue) {
        if (optionValues.value.type === "time") {
          display.value = formatTimes(newValue);
        } else if (optionValues.value.type === "number") {
          display.value = newValue;
        } else if (optionValues.value.type === "miles") {
          display.value = `${newValue} miles`;
        }
        context.emit("slider-value", newValue);
      }
    });

    function setSliderValues() {
      // display changes when selection does since selection is watched
      if (optionValues.value.type === "time") {
        if (optionValues.value.time) {
          selection.value = optionValues.value.time;
        } else {
          display.value = "select time";
        }
      } else if (optionValues.value.type === "number") {
        if (optionValues.value.number) {
          selection.value = optionValues.value.number;
        } else {
          display.value = "select value";
        }
      } else if (optionValues.value.type === "miles") {
        if (optionValues.value.number) {
          selection.value = optionValues.value.number;
        } else {
          display.value = "select value";
        }
      }
    }

    function formatTimes(timeValue) {
      let formattedTime = "";
      if (timeValue > 12) {
        if (selection.value === 24) {
          formattedTime = `12:00 AM`;
        } else {
          formattedTime = `${timeValue - 12}:00 PM`;
        }
      } else {
        if (selection.value === 12) {
          formattedTime = `12:00 PM`;
        } else {
          formattedTime = `${timeValue}:00 AM`;
        }
      }
      return formattedTime;
    }

    return {
      label,
      slider,

      selection,
      display,
      optionValues,
    };
  },
});
</script>
