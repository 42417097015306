import { createRouter, createWebHistory } from "@ionic/vue-router";

// firebase imports
import { auth, db } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { routes } from "../routes.js";

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const toRoute = router.resolve(to.path).matched.slice(-1)[0]; // Get the last matched record

  //if route previously existed re-update meta data
  if (toRoute && toRoute.instances.default) {
    toRoute.instances.default.changeMeta();
  }
  onAuthStateChanged(auth, async (user) => {
    // const user = auth.currentUser;
    async function getDocRef() {
      const docRef = doc(db, "permissions", user.uid);
      const docSnap = await getDoc(docRef);
      let level;
      if (docSnap.data()) {
        level = docSnap.data().level;
      } else {
        level = "zero";
      }
      return { level };
    }

    console.log("to: ", to.name, to.path, user);
    if (user !== null) {
      const permission = await getDocRef();
      console.log("permission: ", permission);
      //authenticated and permission level verify
      if (permission.level === "verify") {
        if (
          to.name === "email-verification" ||
          to.name === "start" ||
          to.name === "terms" ||
          to.name === "privacy" ||
          to.name === "contact" ||
          to.name === "FAQ" ||
          to.name === "blog" ||
          to.name === "storm-data"
        ) {
          next();
        } else {
          next("email-verification");
        }
      }
      //authenticated and permission level one
      else if (permission.level === "owner" || permission.level === "admin") {
        if (
          // can't go to login or signup
          to.name === "start" ||
          to.name === "terms" ||
          to.name === "privacy" ||
          to.name === "contact" ||
          to.name === "FAQ" ||
          to.name === "blog" ||
          to.name === "directory" ||
          to.name === "NotFound" ||
          to.name === "map" ||
          to.name === "appointments" ||
          to.name === "storm-data" ||
          to.name === "manage-markers" ||
          to.name === "manage-layers"

          // || to.name === undefined
        ) {
          console.log("next to: ", to.name);
          next();
        } else {
          console.log("deny to: ", to.name);
          //deny access
          return false;
        }
      }
      // contractor employee
      else if (permission.level === "employee") {
        if (
          // can't go to login or signup
          to.name === "start" ||
          to.name === "terms" ||
          to.name === "privacy" ||
          to.name === "contact" ||
          to.name === "FAQ" ||
          to.name === "blog" ||
          to.name === "directory" ||
          to.name === "NotFound" ||
          to.name === "map" ||
          to.name === "appointments" ||
          to.name === "storm-data"
          // || to.name === undefined
        ) {
          console.log("next to: ", to.name);
          next();
        } else {
          console.log("deny to: ", to.name);
          //deny access
          return false;
        }
      } else if (permission.level === "user") {
        return false;
      }
      // no permission level
      else {
        //deny access
        return false;
      }
    }

    // if user is not logged in
    else {
      if (
        to.name === "login" ||
        to.name === "signup" ||
        //login and signup allowed when not logged in
        to.name === "start" ||
        to.name === "terms" ||
        to.name === "privacy" ||
        to.name === "contact" ||
        to.name === "FAQ" ||
        to.name === "blog" ||
        to.name === "directory" ||
        to.name === "NotFound" ||
        to.name === "storm-data"
      ) {
        next();
      } else {
        //deny access
        next("login");
        // return false;
      }
    }
  });
});

export default router;
