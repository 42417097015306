import { loadingController } from "@ionic/vue";

export async function showLoading(message) {
  let displayMessage = "Loading...";
  if (message) {
    displayMessage = message;
  }

  const loading = await loadingController.create({
    message: displayMessage,
  });
  loading.present();
}
