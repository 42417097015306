<template>
  <div>
    <h1 v-if="showRating === true">{{ rating }}</h1>

    <i
      class="rating__star far fa-star"
      v-for="(star, index) in starNumber"
      :key="index"
      :class="{ fas: index < rating, far: index >= rating }"
      @click="updateRating(index + 1)"
    ></i>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  // emits: ["rating-updated"],
  props: {
    startingStars: {
      type: Number,
      default: 0,
    },
    editStars: {
      type: Boolean,
      default: false,
    },
    showRating: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rating = ref(props.startingStars);

    const starNumber = [1, 2, 3, 4, 5];

    // console.log("first star value", rating.value);

    function resetValue() {
      rating.value = 0;
    }

    function updateRating(value) {
      if (props.editStars) {
        rating.value = value;
        this.$emit("rating-updated", value);
        // console.log(rating.value);
      } else {
        console.error("Error: star rating failed to update rating");
      }
    }

    watch(props, (newProps) => {
      rating.value = newProps.startingStars;
    });

    return { starNumber, rating, updateRating, resetValue };
  },
});
</script>

<style scoped>
/* .rating {
  width: 180px;
} */

.rating__star {
  cursor: pointer;
  color: #dabd18b2;
}
</style>
