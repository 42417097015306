<template>
  <ion-icon
    form="HTMLFormElement"
    :id="trig"
    :src="informationCircle"
  ></ion-icon>
  <ion-modal :trigger="trig" id="ion-modal-info-popover">
    <ion-content :scroll-y="true" :scroll-x="false">
      <div class="div-info-popover">
        <slot />
      </div>
    </ion-content>
  </ion-modal>
</template>
<script>
import {
  defineComponent,
  //  ref, watch, onMounted
} from "vue";
import { informationCircle } from "ionicons/icons";
import {
  IonModal,
  IonIcon,
  IonContent,
  // IonItem,
  // IonLabel,
  // IonList,

  // IonAccordion,
  // IonAccordionGroup,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonModal,
    IonIcon,
    IonContent,
  },
  props: {
    trig: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      informationCircle,
    };
  },
});
</script>
<style scoped>
#ion-modal-info-popover {
  --height: 50%;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}
.div-info-popover {
  height: auto;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
