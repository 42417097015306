<template>
  <p>
    These Terms and Conditions ("Terms") govern the use of this appointment
    scheduling service (the "Service") by contractors ("User" or "Users") for
    booking appointments and related invoicing services. By using the Service,
    you agree to be bound by these Terms. If you do not agree to these Terms,
    please refrain from using the Service.
  </p>
  <h3>Booking Appointments</h3>
  <p>
    Users may accept appointments through the Service, subject to availability.
    The first three (3) appointments booked for a User will be free of charge.
    After the first three appointments, Users will be charged a fee of $50.00
    (USD) per appointment, plus any applicable taxes and fees. Please be aware
    that once an appointment is booked, it is considered finalized, and the User
    is responsible for ensuring its completion. We do not guarantee that
    appointments will proceed as planned, and if someone cancels an appointment,
    you will still be required to pay for it.
  </p>
  <h3>Invoicing and Payment</h3>
  <p>
    Users will receive invoices on a monthly basis for all appointments booked
    for the User during that month. Payment is due within fourteen (14) days of
    the invoice date. If a User does not pay the invoice within the specified
    time, their account may be suspended, and a late fee of $50.00 (USD) may be
    applied.
  </p>
  <h3>Account Suspension</h3>
  <p>
    If a User's account is suspended due to non-payment, the User will not be
    able to accept additional appointments until the outstanding invoice and
    late fee are paid in full.
  </p>
  <h3>Cancellation and Refunds</h3>
  <p>
    Users or the Service may cancel this agreement at any time. Users will be
    charged for any appointments booked prior to the cancellation date, and
    these charges will be included in the final invoice. No refunds will be
    provided for appointments booked before the cancellation date.
  </p>
  <h3>Disclaimer of Warranties and Limitation of Liability</h3>
  <p>
    The Service is provided on an "as is" and "as available" basis, without any
    warranties. The Service disclaims all warranties, express or implied,
    including but not limited to warranties of merchantability, fitness for a
    particular purpose, and non-infringement. The Service does not guarantee the
    availability, accuracy, completeness, reliability, or timeliness of the
    Service or any content thereon. To the maximum extent permitted by law, the
    Service shall not be liable for any direct, indirect, incidental, special,
    consequential, or punitive damages arising out of or in connection with the
    use or inability to use the Service, even if the Service has been advised of
    the possibility of such damages. Additionally, the Service is not liable for
    any injuries, damages, or any other negative consequences that may occur
    during appointments.
  </p>
  <h3>Indemnification</h3>
  <p>
    Users agree to indemnify and hold harmless the Service, its affiliates,
    officers, directors, employees, and agents from and against any and all
    claims, damages, obligations, losses, liabilities, costs, and expenses
    (including but not limited to attorney's fees) arising from or related to
    the User's use of the Service or any breach of these Terms.
  </p>
  <h3>Governing Law and Jurisdiction</h3>
  <p>
    These Terms shall be governed by and construed in accordance with the laws
    of the United States of America. Any disputes arising from or related to
    these Terms or the use of the Service shall be subject to the exclusive
    jurisdiction of the courts of the United States of America.
  </p>

  <h3>Changes to Terms</h3>
  <p>
    The Service reserves the right to modify or update these Terms at any time
    without prior notice. By continuing to use the Service after any such
    changes, the User agrees to be bound by the updated Terms.
  </p>
  <h3>Contact</h3>
  <p>
    If you have any questions or concerns regarding these Terms or the Service,
    please contact us through our contact page.
  </p>

  <h3>Privacy Policy</h3>
  <p>
    By using the Service, you agree to the collection and use of your personal
    information in accordance with our Privacy Policy. Please review our Privacy
    Policy to learn more about how we handle your personal information.
  </p>

  <h3>User Conduct</h3>
  <p>
    Users agree to use the Service responsibly and lawfully. Users are
    prohibited from engaging in any activity that violates applicable laws,
    regulations, or the rights of others, including but not limited to
    harassment, discrimination, defamation, or infringement of intellectual
    property rights.
  </p>

  <h3>Termination</h3>
  <p>
    The Service reserves the right, at its sole discretion, to terminate or
    suspend a User's access to the Service without prior notice for any reason,
    including but not limited to violation of these Terms, non-payment of fees,
    or engaging in activities that may harm the Service or other users.
  </p>
</template>
