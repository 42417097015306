<template>
  <modal-base>
    <template v-slot:modalContent>
      <!-- display from Knock Stoppers -->

      <ion-card>
        <img :src="photoUrl" :alt="altText" style="align-self: center" />
        <ion-card-header>
          <!-- <ion-card-subtitle>{{ post.formatedDate }}</ion-card-subtitle> -->
          <ion-card-title>{{ title }}</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          {{ description }}
        </ion-card-content>
      </ion-card>
    </template>
    <template v-slot:reviewFooter>
      <div style="display: flex; width: 100%">
        <div style="width: 50%">
          <ion-button v-if="counter" expand="block" @click="nextSlide(-1)">
            back</ion-button
          >
          <ion-button v-else :disabled="true" expand="block">back</ion-button>
        </div>
        <ion-button
          style="width: 100%"
          expand="block"
          class="button-starter-page"
          @click="nextSlide(1)"
          >{{ button }}</ion-button
        >
      </div>
    </template>
  </modal-base>
  <ion-modal
    ref="modalRef"
    :is-open="modalOpen"
    id="ion-modal-appointment-start"
    @didDismiss="handleModalDismiss"
  >
    <ion-content :scroll-y="true" :scroll-x="false">
      <div class="div-appointment-start">
        <div v-if="infoModal === 'one'">
          <ion-item>
            <ion-label>Set up Billing (1 of 2)</ion-label>
          </ion-item>
          <ion-item>
            <p>
              Please fill out billing information. You will recieve an invoice
              at the end of each month for any appointments booked past the 3
              free appointments. You can modify this information again later.
            </p>
          </ion-item>
        </div>
        <div v-else-if="infoModal === 'two'">
          <ion-item>
            <ion-label>Appointment Preferences (2 of 2)</ion-label>
          </ion-item>
          <ion-item>
            <p>
              Please fill out your appointment prefferences. The values are set
              to the reccomended default. Modify anything you'd like to change
              than click "update" to confirm your changes. If you'd like to
              leave the recomended default settings click either "update" or
              "cancel".
            </p>
          </ion-item>
        </div>

        <div v-else-if="infoModal === 'three'">
          <ion-item>
            <ion-label>Congratulations!</ion-label>
          </ion-item>
          <ion-item>
            <p>
              You are now able to recieve appointments from Knock Stoppers!
              Please read below for more information:
            </p>
          </ion-item>
          <ion-item>
            <ion-label>How to view appointments</ion-label>
          </ion-item>
          <ion-item>
            <p>
              To view and assign appointments click "Appointments" in the main
              menu. From here you can view and change appointment status, and
              assign appointments to employees.
            </p>
          </ion-item>
          <ion-item>
            <ion-label>How to modify billing info</ion-label>
          </ion-item>
          <ion-item>
            <p>
              To modify billing info or to stop recieving appointments click
              "Appointment Billing" in the main menu.
            </p>
          </ion-item>
          <ion-item>
            <ion-label>How to modify appointment prefferences</ion-label>
          </ion-item>
          <ion-item>
            <p>
              To view or modify your appointment prefferences click "Appointment
              Prefferences" in the main menu.
            </p>
          </ion-item>
          <ion-item>
            <ion-label>Questions/comments/concerns</ion-label>
          </ion-item>
          <ion-item>
            <p>
              You can get in touch with use by clicking "Contact" in the main
              menu and filling out the form, we will reach out to you as soon as
              possible. We appreciate your buisness!
            </p>
          </ion-item>
        </div>
        <div v-else-if="infoModal === 'four'">
          <ion-item>
            <ion-label>Opps.. appointments canceled</ion-label>
          </ion-item>
          <ion-item>
            <p></p>
          </ion-item>
        </div>
        <div v-else>
          <p>something else happened</p>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
// import AutocompleteSearch from "../components/AutocompleteSearch.vue";
import AppointmentBilling from "../components/AppointmentBilling.vue";
import { typAsync } from "../composables/typAsync.js";
import AppointmentPreferences from "../components/AppointmentPreferences.vue";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

import {
  IonItem,
  // IonIcon,
  // IonChip,
  IonLabel,
  // IonList,
  // IonInput,
  IonButton,
  IonModal,
  IonContent,
  // IonCheckbox,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  // IonModal,
  // IonContent,
  // loadingController,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
  components: {
    IonButton,
    // SliderComponent,
    IonItem,
    IonLabel,
    // IonFooter,
    // IonChip,
    // IonIcon,
    // InfoPopover,
    // IonList,
    IonModal,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    // IonInput,
    // IonModal,
    // IonContent,
    // IonCheckbox,
    ModalBase,

    // AutocompleteSearch,
    // InfoPopover,
  },
  setup() {
    const nextPage = ref(false);
    const modalRef = ref(null);
    const modalOpen = ref(false);
    const infoModal = ref(null);

    // loading controller function

    const title = ref("");
    const description = ref("");
    const button = ref("");
    const photoUrl = ref("");
    const counter = ref(0);
    const altText = ref("");
    const storage = getStorage();

    const infoArray = [
      {
        title: "How Users Book Appointments",
        // title: "",
        description:
          "Activating appointments enables users of Knock Stoppers to book appointments with your company for storm damage inspections, replacement estimates, and insurance adjustments. Enabling appointments will also increase your rank on Knock Stoppers giving you more visability on the site.",
        photo: "person_booking_appointment_550_400.png",
        button: "next",
        altText: "Knock Stoppers Logo",
      },
      {
        title: "How It Works",
        description:
          "We appreciate you trying out our service, so the first 3 appointments are free. You are able to set exactly when and how many appointments that you'd like to take as well as assign appointments to employees. Each appointment after the first 3 cost $50 before taxes and will be invoiced at the end of each month for the total number of appointments that were booked. You can cancel at any time, at which point you will be invoiced for any remaining appointments. ",
        photo: "person_clicking_preferences_550_400.png",
        button: "next",
        altText: "A map with an x over a house that doesn't want to be knocked",
      },
      {
        title: "Our Misson",
        description:
          "Our goal is to provide a service that connects homeowners with contractors in more appropriate way than door knocking. We'd love for you to be part of that mision.",
        photo: "contractor_meeting_homeowner_550_400.png",
        button: "get started",
        altText: "A women holding a phone showing a top rated contractor",
      },
    ];

    function nextSlide(number) {
      counter.value = counter.value + number;

      if (counter.value > 2) {
        // console.log("last reached");
        openModal();
      } else {
        assignValues();
      }
    }

    function assignValues() {
      title.value = infoArray[counter.value].title;
      description.value = infoArray[counter.value].description;
      photoUrl.value = infoArray[counter.value].photoUrl;
      button.value = infoArray[counter.value].button;
      altText.value = infoArray[counter.value].altText;
    }

    onMounted(() => {
      return typAsync(async () => {
        for (let i = 0; i < infoArray.length; i++) {
          let imageRef = storageRef(
            storage,
            `appointmentBooking/${infoArray[i].photo}`
          );

          await getDownloadURL(imageRef)
            .then((url) => {
              infoArray[i].photoUrl = url;
            })
            .catch((error) => {
              console.error(error);
            });
        }
        // const imageRef = storageRef(storage, `${bucketName}/${imageName.value}`);

        assignValues();
      });
    });

    watch(infoModal, (newValue) => {
      if (newValue) {
        modalOpen.value = true;
      } else {
        modalOpen.value = false;
      }
    });

    // watch(modalOpen, (newValue) => {
    //   console.log("info modal vlaue: ", newValue);
    //   if (!newValue) {
    //     infoModal.value = null;
    //   }
    // });

    function handleModalDismiss() {
      // console.log("info modal dismissed");
      if (infoModal.value === "three") {
        infoModal.value = null;
        location.reload();
      } else {
        infoModal.value = null;
      }
    }

    async function openModal() {
      // set up modalOne -> appointment billing
      const modalOne = await modalController.create({
        component: AppointmentBilling,
      });
      await modalOne.present();

      // display infoModal one
      infoModal.value = "one";

      // dismiss modalOne before presenting modalTwo
      const dismissValueOne = await modalOne.onWillDismiss();
      await modalOne.dismiss();
      // console.log("dismissValue", dismissValueOne);

      if (dismissValueOne.data === "confirm") {
        // set up modalTwo -> appointment preferences
        const modalTwo = await modalController.create({
          component: AppointmentPreferences,
        });
        await modalTwo.present();

        // display infoModal two
        infoModal.value = "two";

        // dismiss modalTwo before displaying confirmation
        // const dismissValueTwo = await modalTwo.onWillDismiss();
        await modalTwo.onWillDismiss();
        await modalTwo.dismiss();
        // console.log("dismissValue", dismissValueTwo);

        // if (dismissValueTwo.data === "confirm") {
        //   // display confirmation and close modal
        infoModal.value = "three";
        // } else {
        //   infoModal.value = "four";
        // }
      }
    }

    return {
      infoModal,
      nextSlide,
      infoArray,
      title,
      description,
      photoUrl,
      button,
      counter,
      altText,
      modalRef,
      modalOpen,
      // changeMeta,
      nextPage,
      handleModalDismiss,
    };
  },
});
</script>

<style scoped>
#ion-modal-appointment-start {
  --height: 50%;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}

.div-appointment-start {
  height: auto;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
