<template>
  <!-- <Suspense> -->
  <!-- <template #default> -->

  <ion-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} </template>
    </metainfo>
    <ion-menu content-id="main-content">
      <ion-content>
        <ion-list>
          <ion-item color="primary">
            <ion-label>
              <h1 style="color: gainsboro">Pages</h1>
            </ion-label>
          </ion-item>
          <ion-item
            v-if="
              userData.permission === 'owner' ||
              userData.permission === 'employee' ||
              userData.permission === 'admin'
            "
            color="light"
            button
            detail="false"
            @click="goToThis('map')"
            >Canvas Map</ion-item
          >

          <ion-item
            color="light"
            button
            detail="false"
            @click="goToThis('directory')"
            >Blog</ion-item
          >

          <ion-item
            color="light"
            button
            detail="false"
            @click="goToThis('storm-data/home')"
            >Storm Data</ion-item
          >
          <ion-item
            v-if="
              userData.permission === 'owner' ||
              userData.permission === 'employee' ||
              userData.permission === 'admin'
            "
            color="light"
            button
            detail="false"
            @click="goToThis('appointments')"
            >Appointments</ion-item
          >
          <div
            v-if="
              userData.permission === 'owner' || userData.permission === 'admin'
            "
          >
            <ion-item color="primary">
              <ion-label>
                <h1 style="color: gainsboro">Owner Tools</h1>
              </ion-label>
            </ion-item>
            <ion-item
              v-if="showAppointments"
              color="light"
              button
              detail="false"
              @click="openModal(AppointmentBilling)"
            >
              Appointment Billing
            </ion-item>
            <ion-item
              v-if="showAppointments"
              color="light"
              button
              detail="false"
              @click="openModal(AppointmentPreferences)"
            >
              Appointment Prefferences
            </ion-item>
            <ion-item
              color="light"
              button
              detail="false"
              @click="openModal(EditKnockStoppersModal)"
            >
              Edit Knock Stoppers Info
            </ion-item>
            <ion-item
              color="light"
              button
              detail="false"
              @click="goToThis('manage-markers')"
              >Manage Map Markers
            </ion-item>
            <ion-item
              color="light"
              button
              detail="false"
              @click="goToThis('manage-layers')"
              >Manage Map Layers
            </ion-item>
          </div>
        </ion-list>
      </ion-content>

      <ion-footer>
        <div style="padding-left: 5px; padding-right: 5px">
          <ion-button
            class="button-app-vue"
            v-if="
              (userData.permission === 'owner' ||
                userData.permission === 'admin') &&
              !showAppointments
            "
            expand="block"
            @click="openModal(AppointmentStartModal)"
            >Claim 3 Free Appointments</ion-button
          >
        </div>

        <div class="div-menu-APP">
          <a class="font-APP" @click="goToThis('FAQ')">FAQ</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('contact')">Contact</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('terms')">Terms</a>
          &nbsp; | &nbsp;
          <a class="font-APP" @click="goToThis('privacy')">Privacy</a>
        </div>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonFooter,
  // IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonButton,
  // IonHeader,
  modalController,
  // loadingController,
  IonContent,
  // IonTitle,
  // IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "vue";
import AppointmentPreferences from "@/components/AppointmentPreferences.vue";
import EditKnockStoppersModal from "@/components/EditKnockStoppersModal.vue";
import AppointmentBilling from "@/components/AppointmentBilling.vue";
import AppointmentStartModal from "@/components/AppointmentStartModal.vue";

// import { getUserData } from "@/composables/getUserData.js";
import { useRouter } from "vue-router";
import { ref } from "vue";
// import { onAuthStateChanged } from "firebase/auth";
import { displayError } from "./composables/displayError.js";
import { typAsync } from "./composables/typAsync.js";
import { useMeta } from "vue-meta";
// import { auth, db } from "./firebase/config";
// import { doc, getDoc } from "firebase/firestore";
import photoOne from "./images/KN_LOGO_COLOR_300_50.png";
import { getCurrentInstance } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonMenu,
    IonFooter,
    // IonButton,
    IonItem,
    IonList,
    IonLabel,
    IonButton,
    // IonHeader,
    // AccountAcord,
    IonContent,
    // IonTitle,
    // IonToolbar,
    // LoadingSkeleton,
  },

  setup() {
    useMeta({
      title: "Knock Nexus - One Stop Storm Restoration Hub", //60 character max
      description:
        "Manage your Knock Stoppers contractor account with ease - edit profiles, view storm data, canvas areas, and accept appointments on a user-friendly platform.", //155 charactrer max
      og: {
        title: "Knock Nexus - One Stop Storm Restoration Hub", //60 character max
        description:
          "Manage your Knock Stoppers contractor account with ease - edit profiles, view storm data, canvas areas, and accept appointments on a user-friendly platform.",
        image: photoOne,
      },
      twitter: {
        card: "summary",
        title: "Knock Nexus - One Stop Storm Restoration Hub",
        description:
          "Manage your Knock Stoppers contractor account with ease - edit profiles, view storm data, canvas areas, and accept appointments on a user-friendly platform.",
        image: photoOne,
      },
    });

    // const userData = ref();
    const userExists = ref(false);
    const instance = getCurrentInstance();
    const userData = ref(instance.appContext.config.globalProperties.$userData);

    const showAppointments = ref(false);
    // const { user } = getUser();

    const router = useRouter();

    function goToThis(pageName) {
      return typAsync(async () => {
        router.push(`/${pageName}`);
      });
    }

    async function openModal(modalType, reviewType) {
      try {
        if (
          (reviewType === "review" || reviewType === "knock") &&
          userExists.value === false
        ) {
          goToThis("login");
        } else {
          const modal = await modalController.create({
            component: modalType,

            // componentProps: {
            // userData: userData.value,
            // reviewType: reviewType,
            // },
          });
          modal.present();
          await modal.onWillDismiss();
        }
      } catch (error) {
        console.log("error: ", error);
        displayError(error);
      }
    }

    return {
      goToThis,
      AppointmentBilling,
      AppointmentPreferences,
      openModal,
      EditKnockStoppersModal,
      userData,
      AppointmentStartModal,
      showAppointments,
    };
  },
});
</script>

<style scoped>
.div-menu-APP {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 110px;

  align-items: center;
  justify-content: center;
  /* max-height: 100px; */
  /* margin-top: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  color: grey;
}

.font-APP {
  color: grey;
}

.font-APP:hover {
  cursor: pointer;
  opacity: 0.8;
}

.button-app-vue {
  --background: #efc05a;
  --color: black;
}
</style>
