<template>
  <modal-base>
    <template v-slot:modalContent v-if="userData.billingInfo">
      <!-- display from Knock Stoppers -->

      <ion-list>
        <!-- edit display info -->
        <ion-item color="light">
          <ion-label>Billing Info</ion-label>
          <info-popover trig="edit-display-info">
            <h3>Company Name:</h3>
            <p>This is the company name that will be shown on your invoice.</p>
            <h3>Email Address:</h3>
            <p>
              This is the email address where the electronic invoice will be
              sent.
            </p>
            <h3>Address:</h3>
            <p>
              This is the address where overdue invoices will be send if the
              electronic invoice is not paid within 14 days.
            </p>
            <h3>Phone Number:</h3>
            <p>
              This is the phone number we will reach out to if there are any
              issues with invoicing.
            </p>
          </info-popover>
        </ion-item>
        <ion-item>
          <ion-label>Company Name:</ion-label>
          <ion-input
            placeholder="enter name"
            v-model="billingInfo.name"
            @ionInput="validateName($event.target.value)"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="nameValidation"
          ></ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-label>Email Address:</ion-label>
          <ion-input
            @ionInput="validateEmail($event.target.value)"
            placeholder="email@provider.com"
            v-model="billingInfo.email"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="emailValidation"
          ></ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-label> Address: </ion-label>
          <autocomplete-search
            @search-location="updateAddress"
            :displayAddressButton="true"
            :displayCheckBox="true"
            :startingAddress="billingInfo.formattedAddress"
          >
          </autocomplete-search>
        </ion-item>
        <ion-item>
          <ion-label>Phone Number:</ion-label>
          <ion-input
            placeholder="888-888-8888"
            v-model="billingInfo.phoneNumber"
            @ionInput="validatePhone($event.target.value)"
          ></ion-input>
          <ion-checkbox
            :disabled="true"
            v-model="phoneValidation"
          ></ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-label>
            <ion-button id="terms-trigger" color="light"
              >Terms and Conditions</ion-button
            ></ion-label
          >
          <ion-checkbox v-model="terms" :disabled="true"></ion-checkbox>
        </ion-item>
        <div v-if="newBilling === 'false'">
          <ion-item>
            <ion-label>Appointment Acctivation</ion-label>
            <ion-button
              v-if="userData.appointmentsActivated"
              :disabled="true"
              color="success"
              >Active</ion-button
            >
            <ion-button v-else :disabled="true" color="danger"
              >Deactivated</ion-button
            >
          </ion-item>
        </div>
        <ion-modal trigger="terms-trigger" id="ion-modal-review-terms">
          <ion-content class="ion-padding">
            <ion-item color="light">
              <ion-label> Terms and Conditions: </ion-label>
            </ion-item>
            <div style="padding: 10px">
              <appointment-terms></appointment-terms>
            </div>

            <ion-item>
              I've read and agree to these terms and conditions
              <ion-checkbox v-model="terms"></ion-checkbox>
            </ion-item>
          </ion-content>
        </ion-modal>
      </ion-list>
    </template>
    <template v-slot:reviewFooter>
      <ion-button
        expand="block"
        @click="updateBilling()"
        v-if="billingValuesChanged && terms"
        >Update</ion-button
      ><ion-button v-else :disabled="true" expand="block">Update</ion-button>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "../components/ModalBase.vue";
import AutocompleteSearch from "../components/AutocompleteSearch.vue";
import AppointmentTerms from "../components/AppointmentTerms.vue";
import InfoPopover from "../components/InfoPopover.vue";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/config";

import {
  IonItem,
  // IonIcon,
  // IonChip,
  IonLabel,
  IonList,
  IonInput,
  IonButton,
  IonCheckbox,
  IonModal,
  IonContent,
  // loadingController,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted, watch } from "vue";
import { getUserData } from "../composables/getUserData.js";
import { typAsync } from "../composables/typAsync.js";

export default defineComponent({
  components: {
    IonButton,
    // SliderComponent,
    IonItem,
    IonLabel,
    // IonFooter,
    // IonChip,
    // IonIcon,
    // InfoPopover,
    IonList,
    IonInput,
    IonModal,
    IonContent,
    IonCheckbox,
    ModalBase,
    AutocompleteSearch,
    InfoPopover,
    AppointmentTerms,
  },
  setup() {
    const userData = ref("");
    const newBilling = ref(false);
    const billingInfo = ref({
      name: "",
      phoneNumber: "",
      formattedAddress: "",
      email: "",
    });
    // validations
    const addressValidation = ref(false);
    const nameValidation = ref(false);
    const phoneValidation = ref(false);
    const emailValidation = ref(false);
    const terms = ref(false);
    const billingValuesChanged = ref(false);

    onMounted(() => {
      return typAsync(async () => {
        // get contractorId
        userData.value = await getUserData();

        // console.log("contractor values: ", userData.value);

        // test if userBilling exists and set billingInfo so that can be tested for changes later
        setBillingInfo(userData.value);
        userData.value = {
          ...userData.value,
          billingInfo: { ...billingInfo.value },
        };
      });
    });

    function setBillingInfo(userValues) {
      if (userData.value.billingInfo) {
        let userBilling = userValues.billingInfo;
        // console.log("exisitng billing");
        if (userBilling.name) {
          billingInfo.value.name = userBilling.name;
          nameValidation.value = true;
        }
        if (userBilling.phoneNumber) {
          billingInfo.value.phoneNumber = userBilling.phoneNumber;
          phoneValidation.value = true;
        }
        if (userBilling.formattedAddress) {
          billingInfo.value.formattedAddress = userBilling.formattedAddress;
          addressValidation.value = true;
        }
        if (userBilling.email) {
          billingInfo.value.email = userBilling.email;
          emailValidation.value = true;
        }
      } else {
        // console.log("new billing");
        //newBilling is to determine if allow button to stop getting appointments
        newBilling.value = true;
      }
    }

    // watch function watches for changes in billing info
    watch(
      billingInfo,
      (newValue) => {
        // console.log("billinginfo: ", newValue);
        // console.log("userValue: ", userData.value.billingInfo);
        if (
          (newValue.name !== userData.value.billingInfo.name ||
            newValue.phoneNumber !== userData.value.billingInfo.phoneNumber ||
            newValue.email !== userData.value.billingInfo.email ||
            newValue.formattedAddress !==
              userData.value.billingInfo.formattedAddress) &&
          addressValidation.value &&
          nameValidation.value &&
          phoneValidation.value &&
          emailValidation.value
        ) {
          // console.log("billingValues changed");
          billingValuesChanged.value = true;
        } else {
          billingValuesChanged.value = false;
        }
      },
      { deep: true }
    );

    function updateAddress(addressValues) {
      return typAsync(async () => {
        billingInfo.value.formattedAddress = addressValues.formattedAddress;

        addressValidation.value = true;

        // console.log("updateAddress: ", userData.value);
      });
    }

    // validate name
    function validateName(nameValue) {
      if (nameValue.length) {
        nameValidation.value = true;
      } else {
        nameValidation.value = false;
      }
    }

    // validate phone number
    function validatePhone(phone) {
      const phoneNumber = phone.replace(/\D/g, ""); // Remove all non-numeric characters
      const formattedPhoneNumber = phoneNumber
        .slice(0, 10)
        .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // Format the phone number
      phone = formattedPhoneNumber; // Update the input value

      if (formattedPhoneNumber.split("-").length === 3) {
        phoneValidation.value = true;
      } else {
        phoneValidation.value = false;
      }
    }

    // validate email
    function validateEmail(tempEmail) {
      var emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (emailRegex.test(tempEmail)) {
        emailValidation.value = true;
      } else {
        emailValidation.value = false;
      }
    }

    function updateBilling() {
      if (billingValuesChanged.value && terms.value) {
        return typAsync(async () => {
          // console.log("update contractor values: ", billingInfo.value);

          // set contractors doc
          await updateDoc(doc(db, "accounts", userData.value.userId), {
            billingInfo: billingInfo.value,
            appointmentsActivated: true,
          });
          modalController.dismiss("confirm");
        });
      }
    }

    return {
      billingInfo,
      updateBilling,
      updateAddress,
      userData,
      addressValidation,
      validateName,
      newBilling,
      validateEmail,
      validatePhone,
      phoneValidation,
      nameValidation,
      emailValidation,
      terms,
      billingValuesChanged,
    };
  },
});
</script>

<style scoped>
#ion-modal-review-terms {
  --height: 50%;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.3;
}
</style>
