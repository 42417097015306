import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";
import { displayError } from "./composables/displayError.js";
import { getUserData } from "./composables/getUserData.js";
import { typAsync } from "./composables/typAsync.js";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
// import "@ionic/vue/css/text-alignment.css";
// import "@ionic/vue/css/text-transformation.css";
// import "@ionic/vue/css/flex-utils.css";
// import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
// import "./theme/core.css";

// Apply dark mode stylesheet
// import "@ionic/vue/css/theme-dark.css";

/* Global components*/
import BaseLayout from "./components/BaseLayout.vue";

import { createMetaManager } from "vue-meta";

initApp();

function initApp() {
  return typAsync(async () => {
    // const app = createApp(App).use(IonicVue).use(router)
    let app = createApp(App)
      .use(IonicVue)
      .use(router)
      .use(createMetaManager(), {
        ssrAppId: "my-unique-id-knock-nexus",
      });

    // add global variables for userData
    const userData = await getUserData();
    console.log("got user data");
    app.config.globalProperties.$userData = userData;

    // Add global error handler
    app.config.errorHandler = (err) => {
      displayError(err);
    };

    // Add window.onerror handler
    window.onerror = function (message, source, lineno, colno, error) {
      displayError(error);
    };

    //Register basecomponent for gloabal use
    app.component("base-layout", BaseLayout);

    //mount app
    router.isReady().then(() => {
      app.mount("#app");
    });
  });
}
