import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase/config";

export async function getUserData() {
  let userData = "";

  async function getUser(userId) {
    const docRef = doc(db, "accounts", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error("Error: getUserData does not exist");
      return "";
    }
  }

  async function getPermissions(userId) {
    const docSnap = await getDoc(doc(db, "permissions", userId));

    if (docSnap.data()) {
      return docSnap.data().level;
    } else {
      return "zero";
    }
  }

  await new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let userPermission = "zero";
        userData = await getUser(user.uid);
        userPermission = await getPermissions(user.uid);
        userData = {
          ...userData,
          permission: userPermission,
        };
      }
      // console.log("userData: ", userData, "");
      resolve();
    });

    // Clean up the listener
    return () => unsubscribe();
  });

  // console.log("getuserdata tried: ", auth);
  return userData;
}
