<!-- this base layout will be used on every page so it is a initalized as a global component in main.js -->

<template>
  <ion-header>
    <ion-toolbar class="toolbar-base-layout">
      <ion-buttons slot="start" style="flex-basis: 25%">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>

      <div class="div-base-layout">
        <ion-img
          :src="photoOne"
          slot="center"
          class="ks-img-base-layout"
          style="height: 25px; cursor: pointer"
          @click="goToThis('map')"
        ></ion-img>
      </div>
      <nav-bar slot="end" style="flex-basis: 25%"></nav-bar>
    </ion-toolbar>
  </ion-header>
  <!--  Page content -->
  <div fullscreen class="container-base-layout">
    <ion-content
      v-if="fullscreenDisplay"
      class="content-base-layout-fullscreen"
      scroll-y="false"
      scroll-x="false"
      :fullscreen="true"
    >
      <slot />
    </ion-content>
    <ion-content
      v-else
      class="content-base-layout-app"
      scroll-y="true"
      :fullscreen="true"
    >
      <slot />
    </ion-content>
  </div>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonImg,
} from "@ionic/vue";

import NavBar from "@/components/NavBar.vue";
import photoOne from "../images/KN_LOGO_WHITE_300_50.png";
// import AccountAcord from "@/components/AccountAcord.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
// import getUser from "@/composables/getUser";

export default {
  // props: ["pageTitle", "pageDefaultBackLink"],
  components: {
    IonHeader,
    IonContent,
    IonToolbar,
    IonButtons,
    NavBar,
    IonMenuButton,
    IonImg,
  },
  props: {
    fullscreenDisplay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const userData = ref();
    const router = useRouter();
    // const { user } = getUser();

    function goToThis(pageName) {
      router.push(`/${pageName}`);
    }

    return {
      goToThis,
      userData,
      photoOne,
    };
  },
};
</script>

<style scoped>
.container-base-layout {
  height: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.content-base-layout-fullscreen {
  /* height: 100%; */

  /* padding-left: 3%;
  padding-right: 3%; */
  /* margin-right: 1%;
  margin-left: 4%; */
  height: 100%;
  width: 100%;
  /* overflow-y: scroll; */
}

.content-base-layout-app {
  /* height: 100%; */

  /* padding-left: 3%;
  padding-right: 3%; */
  margin-right: 1%;
  margin-left: 4%;
  min-width: 350px;
  max-width: 800px;
  height: 100%;
  overflow-y: scroll;
}

.toolbar-base-layout {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

.ks-img-base-layout:hover {
  cursor: pointer;
  opacity: 0.8;
}

.div-base-layout {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>
